import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, ButtonTypes } from '../Button';
import { ModalHeader } from './ModalHeader';
import { TenantConnection, useDeleteTenantConnectionMutation } from '../../store/tenant-connections';

type Props = {
  onClose: () => void;
  connection: TenantConnection | undefined;
};

export function DeleteTenantConnectionModal(props: Props) {
  const [deleteTenantConnection, deleteTenantConnectionState] = useDeleteTenantConnectionMutation();
  const [lazyConnection, setLazyConnection] = useState<TenantConnection | undefined>(props.connection);

  useEffect(() => {
    if (props.connection !== undefined) {
      setLazyConnection(props.connection);
    }
  }, [props.connection, setLazyConnection]);

  const onSubmit = async () => {
    try {
      if (props.connection !== undefined) {
        await deleteTenantConnection(props.connection.id);
      }
      props.onClose();
    } catch (err) {}
  };

  return (
    <ReactModal
      isOpen={!!props.connection}
      onRequestClose={() => {
        props.onClose();
      }}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[600px] max-w-full h-[300px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Delete secret modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-4 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader
            title={`Delete Connection ${lazyConnection?.name || ''}`}
            isLoading={deleteTenantConnectionState.isLoading}
          />

          <div className="my-4">
            <p className="text-md font-light text-center">Are you sure you want to delete this connection?</p>
          </div>
          <div className="my-2">
            <p className="text-xs font-light text-center text-gray-500">
              Deleting a connection will not affect any established workflows. If this connection should no longer be
              used, ensure workflows using it are deleted too.
            </p>
          </div>

          <div className="flex flex-col gap-4 mx-4">
            <Button
              fullWidth
              variant={ButtonTypes.Primary}
              disabled={deleteTenantConnectionState.isLoading}
              text="Delete"
              type="submit"
              onClick={() => {
                onSubmit();
              }}
            />
            <Button
              fullWidth
              variant={ButtonTypes.Secondary}
              disabled={deleteTenantConnectionState.isLoading}
              text="Cancel"
              type="button"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
