import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { env } from '../env';
import { prepHeaders } from './utils';

export type Role = {
  id: string;
  name: string;
};

export const rolesApi = createApi({
  reducerPath: 'roles',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_FQDN}/identity/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Roles],
  endpoints: (builder) => ({
    getRoles: builder.query<Array<Role>, void>({
      query: () => {
        return {
          url: `/roles`
        };
      },
      providesTags: [TagTypes.Roles]
    })
  })
});

export const { useGetRolesQuery } = rolesApi;
