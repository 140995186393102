import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { prepHeaders } from './utils';
import { env } from '../env';
import { ISODate } from '../utils/types';

type TenantSecretTags = { [key: string]: string };

export type TenantSecret = {
  name: string;
  tags: TenantSecretTags;
  createdAt: ISODate;
  updatedAt: ISODate;
};

export type TenantSecretCreate = {
  name: string;
  value: string;
  tags: TenantSecretTags;
};

export type TenantSecretUpdate = {
  name: string;
  value: string;
  tags: TenantSecretTags;
};

export const tenantSecretsApi = createApi({
  reducerPath: 'secrets',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_ENDPOINT_APP}/tenant-secret/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.TenantSecrets],
  endpoints: (builder) => ({
    getTenantSecrets: builder.query<Array<TenantSecret>, {}>({
      query: (options) => {
        return {
          url: ``,
          params: {},
          method: 'GET'
        };
      },
      providesTags: [TagTypes.TenantSecrets]
    }),

    getTenantSecret: builder.query<TenantSecret, string>({
      query: (secretName: string) => {
        return {
          url: `/${secretName}`,
          method: 'GET'
        };
      },
      providesTags: [TagTypes.TenantSecrets]
    }),

    upsertTenantSecret: builder.mutation<TenantSecret, TenantSecretUpdate>({
      query: (data: TenantSecretUpdate) => {
        return {
          url: `/${data.name}`,
          method: 'PUT',
          body: {
            value: data.value,
            tags: data.tags
          }
        };
      },
      invalidatesTags: [{ type: TagTypes.TenantSecrets }]
    }),

    deleteTenantSecret: builder.mutation<void, string>({
      query: (name: string) => {
        return {
          url: `/${name}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: [{ type: TagTypes.TenantSecrets }]
    })
  })
});

export const {
  useGetTenantSecretsQuery,
  useGetTenantSecretQuery,
  useUpsertTenantSecretMutation,
  useDeleteTenantSecretMutation
} = tenantSecretsApi;
