import clsx from 'clsx';
import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

type SideMenuProps = {
  header?: string;
  menuItems?: Array<{
    label: string;
    renderIcon: () => ReactElement;
    to: string;
  }>;
};

export const SideMenu = ({ header, menuItems }: SideMenuProps) => {
  const { pathname } = useLocation();
  return (
    <aside className=" md:w-64 flex-none border-b md:border-b-0 md:border-r font-inter border-opacity-10 border-gray-800 p-4 md:p-8">
      {header && <h2 className="text-lg font-semibold tracking-tight mb-2 md:mb-6">{header}</h2>}

      <ul className="text-sm text-gray-800 text-opacity-60 transition space-y-2">
        {menuItems?.map((item) => {
          const icon = item.renderIcon();
          return (
            <Link
              to={item.to}
              key={item.to}
              className={clsx(
                'flex items-center hover:text-gradient-right',
                pathname === item.to && 'text-gradient-right'
              )}
            >
              <li className="flex items-center">
                {icon} {item.label}
              </li>
            </Link>
          );
        })}
      </ul>
    </aside>
  );
};
