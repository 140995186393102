import ReactModal from 'react-modal';
import { ModalHeader } from './ModalHeader';
import { TenantConnection } from '../../store/tenant-connections';
import { Connector, ConnectorConfigValueKind } from '../../store/connectors';
import { Button, ButtonTypes } from '../Button';
import { capitalise, readableDate } from '../../utils';
import { LockClosedIcon } from '@heroicons/react/24/outline';

type Props = {
  onClose: () => void;
  connection: TenantConnection | undefined;
  connector: Connector | undefined;
};

export function ViewTenantConnectionModal(props: Props) {
  return (
    <ReactModal
      isOpen={!!props.connection}
      onRequestClose={() => {
        props.onClose();
      }}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[600px] max-w-full h-[680px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Create connection modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-2 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader title={props.connection?.name || 'Connection'} isLoading={false} />
          <div className="my-2 flex flex-row items-center">
            <p className="text-sm font-thin">{props.connection?.description}</p>
          </div>

          {props.connection && (
            <div className="flex flex-col flex-grow gap-2 my-4">
              {props.connector && (
                <div>
                  <label className="text-sm">Connector</label>
                  <p className="font-thin overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {props.connector.displayName}
                  </p>
                </div>
              )}
              <div>
                <label className="text-sm">Created</label>
                <p className="font-thin">{readableDate(props.connection.createdAt.toString())}</p>
              </div>
              <div>
                <label className="text-sm">Updated</label>
                <p className="font-thin">{readableDate(props.connection.updatedAt.toString())}</p>
              </div>
              <div>
                <label className="flex flex-row gap-1 items-center">
                  <label className="text-sm">Configuration</label>
                </label>
                <div className="flex flex-col gap-y-1 my-2">
                  {props.connection?.configValues.map((configValue) => (
                    <div className="mx-4 flex flex-col">
                      <label className="text-sm">{capitalise(configValue.name)}</label>
                      {configValue.kind === ConnectorConfigValueKind.SecretRef ? (
                        <div className="flex flex-row gap-x-1 items-center">
                          <div className="w-[18px] flex-shrink-0">
                            <LockClosedIcon />
                          </div>
                          <div className="font-thin overflow-hidden overflow-ellipsis whitespace-nowrap">
                            {configValue.secretRef}
                          </div>
                        </div>
                      ) : (
                        <div className="font-thin overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {configValue.value}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4 m-4">
            <Button
              fullWidth
              variant={ButtonTypes.Secondary}
              text="Close"
              type="button"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
