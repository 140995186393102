import { Link } from 'react-router-dom';
import {
  WorkflowsWrapper,
  WorkflowsList,
  WorkflowSummary,
  WorkflowAnalytics,
  WorkflowRuns,
  WorkflowInstanceWrapper
} from '../pages';
import { WorkflowRunsWrapper } from '../pages/WorkflowSingle/WorkflowRunsWrapper';
import { WorkflowInstance as WorkflowInstanceType, Workflow as WorkflowType } from '../store/workflows';
import { defaultHandle, workflowNameLoader, workflowRunNameLoader } from './utils.routes';
import { WorkflowEditor } from '../pages/WorkflowSingle/WorkflowEditor';

export const WORKFLOWS_ROUTES = [
  {
    path: 'workflows',
    handle: { crumb: () => <Link to="/workflows/active">Workflows</Link> },
    element: <WorkflowsWrapper />,
    children: [
      {
        path: 'active',
        element: <WorkflowsList />,
        handle: defaultHandle
      },
      {
        path: 'archived',
        element: <WorkflowsList />,
        handle: defaultHandle
      }
    ]
  },
  {
    element: <WorkflowInstanceWrapper />,
    handle: { crumb: () => <Link to="/workflows/active">Workflows</Link> },
    children: [
      {
        path: '/workflows/:workflowId/summary',
        element: <WorkflowSummary />,
        loader: workflowNameLoader,
        handle: {
          crumb: (loaderData: { workflowId: string; data: WorkflowType }) => {
            if (loaderData.data) {
              return <Link to={`/workflows/${loaderData.data.id}/summary`}>{loaderData.data.name ?? ''} Summary</Link>;
            }
            return <p>Summary</p>;
          }
        }
      },
      {
        path: '/workflows/:workflowId/analytics',
        element: <WorkflowAnalytics />,
        loader: workflowNameLoader,
        handle: {
          crumb: (loaderData: { workflowId: string; data: WorkflowType }) => {
            if (loaderData.data) {
              return (
                <Link to={`/workflows/${loaderData.data.id}/analytics`}>{loaderData.data.name ?? ''} Analytics</Link>
              );
            }
            return <p>Analytics</p>;
          }
        }
      },
      {
        path: '/workflows/:workflowId/editor',
        element: <WorkflowEditor />,
        loader: workflowNameLoader,
        handle: {
          crumb: (loaderData: { workflowId: string; data: WorkflowType }) => {
            if (loaderData.data) {
              return <Link to={`/workflows/${loaderData.data.id}/editor`}>{loaderData.data.name ?? ''} Editor</Link>;
            }
            return <p>Editor</p>;
          }
        }
      },

      {
        path: '/workflows/:workflowId/runs',
        element: <WorkflowRunsWrapper />,
        loader: workflowNameLoader,
        handle: {
          crumb: (loaderData: { workflowId: string; data: WorkflowType }) => {
            if (loaderData.data) {
              return <Link to={`/workflows/${loaderData.data.id}/runs`}>{loaderData.data.name ?? ''} Runs</Link>;
            }
            return <p>Runs</p>;
          }
        },
        children: [
          {
            path: '/workflows/:workflowId/runs',
            element: <WorkflowRuns />
          },
          {
            path: '/workflows/:workflowId/runs/:instanceId',
            element: <div>Individual Workflow Page Here - needs backend refactor of data shape</div>,
            loader: workflowRunNameLoader,
            handle: {
              crumb: (data: WorkflowInstanceType) => {
                if (data) {
                  return <Link to="/workflows">{data.name}</Link>;
                }

                return <p>Workflow Run</p>;
              }
            }
          }
        ]
      }
    ]
  }
];
