import { useFormContext, useFormState } from 'react-hook-form';
import { capitalise } from '../../utils';

type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

type ReactHookFormInputProps = {
  name: string;
  label: string;
  options: string[];
  unselectedMessage?: string;
};

type Props = SelectProps & ReactHookFormInputProps;

export function ReactHookFormSelect({ name, label, options, unselectedMessage, ...rest }: Props) {
  const formCtx = useFormContext();
  const formState = useFormState({ name });

  return (
    <div>
      <label
        htmlFor={name}
        className={`block mb-0.5 text-xs ${formState.errors[name] ? 'text-red-600' : 'text-gray-500'}`}
      >
        {capitalise(label)} {formState.errors[name] && <span>{formState.errors[name]!.message?.toString()}</span>}
      </label>
      <div className="relative w-full">
        <select
          {...formCtx.register(name)}
          {...rest}
          className={`px-3 py-2 border focus:ring-0 text-gray-600 text-sm font-light rounded-lg focus:border-gradient-right focus:border-opacity-40 block w-full ${
            formState.errors[name] ? 'border-red-600' : 'border-gray-200'
          }`}
        >
          {unselectedMessage && <option value="">{unselectedMessage}</option>}
          {options.map((opt) => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
