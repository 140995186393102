export const LoadingList = () => {
  return (
    <div className="space-y-4">
      <div className="bg-white p-6 rounded-md border border-gray-200 text-sm shadow w-full animate-pulse">
        <div className="flex space-x-4">
          <div className="flex-1 space-y-3 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
      <div className="bg-white p-6 rounded-md border border-gray-200 text-sm shadow w-full animate-pulse">
        <div className="flex space-x-4">
          <div className="flex-1 space-y-3 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
