import { DEFAULT_DATA_SOURCE_VALUES, MdmDemoDataSource } from '.';
import { Button, FileUpload } from '../../../components';
import { DataSourceSetupTable } from './DataSourceTable';

type Props = {
  dataSources: MdmDemoDataSource[];
  setDataSources: (setFn: (prevFiles: MdmDemoDataSource[]) => MdmDemoDataSource[]) => void;
  onClickNext: () => void;
};

export function MdmDemoStepFileUpload(props: Props) {
  return (
    <div>
      <div>
        <h2 className="text-2xl my-2">MDM Demo</h2>
        <p className="text-sm font-normal">Upload source data files such as product data to get started.</p>
      </div>
      <div className="my-8 w-full flex flex-col justify-center items-center max-w-[1200px]">
        {props.dataSources.length > 0 && (
          <div className="my-4 self-start w-full">
            <h2 className="text-xl my-2">Selected Source Files</h2>
            <DataSourceSetupTable
              dataSources={props.dataSources}
              onUpdateDataSource={(idx, data) => {
                props.setDataSources((existingFiles) => {
                  const copy = [...existingFiles];
                  copy.splice(idx, 1, data);
                  return copy;
                });
              }}
              onRemoveDataSource={(idx) => {
                props.setDataSources((existingFiles) => {
                  const copy = [...existingFiles];
                  copy.splice(idx, 1);
                  return copy;
                });
              }}
            />
          </div>
        )}
        <FileUpload
          onFileAdd={(newFiles) => {
            const newDataSources = newFiles.map((file) => ({ ...DEFAULT_DATA_SOURCE_VALUES, file }));
            props.setDataSources((prevDataSources) => [...prevDataSources, ...newDataSources]);
          }}
        />
      </div>
      <div className="my-4 flex flex-row w-full">
        <Button
          text="Start Workflow"
          disabled={props.dataSources.length <= 0}
          onClick={() => {
            props.onClickNext();
          }}
        />
      </div>
    </div>
  );
}
