import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { prepHeaders } from './utils';
import { env } from '../env';

export type Connector = {
  id: string;
  name: string;
  displayName: string;
  description: string;
  version: string;
  tags: string[];
};

export enum ConnectorConfigValueKind {
  SecretRef = 'secretRef',
  Static = 'static'
}

export type ConnectorConfigValue = {
  name: string;
  kind: ConnectorConfigValueKind;
  required: boolean;
};

export const connectorApi = createApi({
  reducerPath: 'Connectors',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_ENDPOINT_APP}/connector/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Connectors],
  endpoints: (builder) => ({
    // type must be defined, endpoint is ok
    getConnectors: builder.query<Connector[], {}>({
      query: (_options) => {
        return {
          url: `/`,
          method: 'GET'
        };
      },
      providesTags: [TagTypes.Connectors]
    }),
    // type must be defined, endpoint is ok
    getConnectorConfig: builder.query<ConnectorConfigValue[], string>({
      query: (connectorId: string) => {
        return {
          url: `/${connectorId}/config`,
          method: 'GET'
        };
      },
      providesTags: [TagTypes.Connectors]
    })
  })
});

export const { useGetConnectorsQuery, useGetConnectorConfigQuery } = connectorApi;
