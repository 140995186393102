import { useState } from 'react';
import { Button, ButtonTypes, Input, LoadingList } from '../../components';
import { useGetUsersQuery } from '../../store/users';
import { Dropdown } from '../../components/Dropdown';
import { Alert } from '../../components/Alert';
import { standardiseError } from '../../utils/errors';

type UsersFilter = { label: string; id: string };

const totalPages = 10;
const applicableFilters: Array<UsersFilter> = [
  {
    label: 'Name',
    id: 'name'
  },
  {
    label: 'Email',
    id: 'email'
  }
];

export const Users = () => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<UsersFilter>(applicableFilters[0]);
  const { data, isLoading, error, isError } = useGetUsersQuery({ page: page - 1, searchTerm, sortKey: filter });

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(Math.max(1, page - 1));
  };

  return (
    <>
      <div className="grid grid-cols-2 items-center justify-between pb-4 md:pb-8">
        <div className="col-span-1 md:max-w-xs">
          <Input
            type="text"
            value={searchTerm}
            label={''}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            id="search"
            placeholder="Search users"
            icon={'MagnifyingGlassCircleIcon'}
          />
        </div>
        <div className="col-span-1 flex flex-row justify-end text-gray-500 font-light text-xs">
          <span className="pr-1">Sort by</span>
          <Dropdown items={applicableFilters} value={filter} onChange={(e) => setFilter(e)} />
        </div>
      </div>

      {isLoading && <LoadingList />}

      {isError && (
        <div className="mb-8">
          <Alert variant="error" message={standardiseError(error)} />
        </div>
      )}

      {!isLoading && data && (
        <>
          <div className="space-y-4">
            {data.data.map((user, i) => (
              <div key={`${user.userId}-${i}`}>
                <div className="bg-white p-4 md:p-8 rounded-md border border-gray-200 text-sm hover:border-gradient-right hover:border-opacity-30 transition-all">
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4">
                      <h3 className="font-semibold">{user.name}</h3>
                      <span className="text-gray-400 hidden md:block">•</span>
                      <p className="text-gray-500 font-light">{user.email}</p>
                      <span className="text-gray-400 hidden md:block">•</span>
                      <p className="text-gray-500 font-light">{user.roles.map((role) => role.name).join(', ')}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {data.total > data.start + data.length && (
            <div className="flex justify-between mt-4 items-center">
              <Button
                variant={ButtonTypes.Primary}
                type="button"
                text={'Previous'}
                onClick={previousPage}
                disabled={data.start === 0}
              />
              <span className="text-xs">{`Page ${page} of ${totalPages}`}</span>
              <Button variant={ButtonTypes.Primary} type="button" text={'Next'} onClick={nextPage} />
            </div>
          )}
        </>
      )}
    </>
  );
};
