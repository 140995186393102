import { useFormContext, useFormState } from 'react-hook-form';
import { capitalise } from '../../utils';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type ReactHookFormInputProps = {
  name: string;
};

type Props = InputProps & ReactHookFormInputProps;

export function ReactHookFormInput({ name, ...rest }: Props) {
  const formState = useFormState({ name });
  const formCtx = useFormContext();

  return (
    <div>
      <label
        htmlFor={name}
        className={`block mb-0.5 text-xs ${formState.errors[name] ? 'text-red-600' : 'text-gray-500'}`}
      >
        {capitalise(name)} {formState.errors[name] && <span>{formState.errors[name]!.message?.toString()}</span>}
      </label>
      <div className="relative w-full">
        <input
          {...formCtx.register(name)}
          {...rest}
          className={`px-3 py-2 border focus:ring-0 text-gray-600 text-sm font-light rounded-lg focus:border-gradient-right focus:border-opacity-40 block w-full ${
            formState.errors[name] ? 'border-red-600' : 'border-gray-200'
          }`}
        />
      </div>
    </div>
  );
}
