type Props = {
  label: string;
  values: string[];
  selectedValue: string;
  onChange: (value: string) => void;
  errorMessage?: string;
};

export function TagSelector({ label, values, selectedValue, onChange, errorMessage }: Props) {
  return (
    <div className="my-1">
      <label htmlFor="tags" className={`block text-xs mb-1 ${errorMessage ? 'text-red-600' : 'text-gray-500'}`}>
        <span>{label}</span> {errorMessage && <span>{errorMessage}</span>}
      </label>
      <div className="flex gap-2 text-sm">
        {values.map((value) => (
          <div
            key={value}
            onClick={() => onChange(value)}
            className={`text-xs px-4 py-1 rounded-full cursor-pointer ${
              selectedValue === value
                ? 'bg-blue-100 text-blue-800'
                : 'bg-gray-100 hover:border-gray-600 hover:bg-gray-200'
            }`}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
}
