import { ConnectorsGrid } from '../../../components';

export function ConnectorsPage() {
  return (
    <div>
      <h1 className="text-2xl my-4">Connectors</h1>
      <p className="my-4 font-light">
        Create a connection from one of our high performance connectors to feed data into and out of your workflows.
      </p>
      <ConnectorsGrid />
    </div>
  );
}
