import React, { useState, useEffect } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

type AlertProps = {
  variant: 'info' | 'warning' | 'error' | 'success';
  message: string;
  autoDismiss?: boolean;
  className?: string;
};

const getGlobalStyles = (variant: AlertProps['variant']) => {
  switch (variant) {
    case 'info':
      return 'bg-blue-50 border-blue-400 text-blue-700';
    case 'warning':
      return 'bg-yellow-50 border-yellow-400 text-yellow-700';
    case 'error':
      return 'bg-red-50 border-red-400 text-red-700';
    case 'success':
      return 'bg-green-50 border-green-400 text-green-700';
  }
};

const getTextStyles = (variant: AlertProps['variant']) => {
  switch (variant) {
    case 'info':
      return 'text-blue-700';
    case 'warning':
      return 'text-yellow-700';
    case 'error':
      return 'text-red-700';
    case 'success':
      return 'text-green-700';
  }
};

const getIcon = (variant: AlertProps['variant']) => {
  switch (variant) {
    case 'info':
      return <ExclamationTriangleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />;
    case 'warning':
      return <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />;
    case 'error':
      return <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />;
    case 'success':
      return <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;
  }
};

const getTimerStyles = (variant: AlertProps['variant']) => {
  switch (variant) {
    case 'info':
      return 'border-blue-400';
    case 'warning':
      return 'border-yellow-400';
    case 'error':
      return 'border-red-400';
    case 'success':
      return 'border-green-400';
  }
};

const visibleForMs = 5000;

export const Alert: React.FunctionComponent<AlertProps> = ({
  variant,
  message,
  autoDismiss = false,
  className
}: AlertProps) => {
  const [dismissed, setDismissed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (autoDismiss) {
      const dismissTimer = setTimeout(() => {
        setDismissed(true);
      }, visibleForMs); // Adjust the time (in milliseconds) for how long the alert should be displayed before dismissing

      // Clear the timer when the component is unmounted or when dismissed
      return () => clearTimeout(dismissTimer);
    }
  }, [autoDismiss]);

  useEffect(() => {
    if (autoDismiss) {
      let timer: NodeJS.Timeout | null = null;
      timer = setTimeout(() => {
        setIsVisible(true);
      }, 50);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [autoDismiss]);

  if (dismissed) {
    return null; // Don't render anything if the alert is dismissed
  }

  return (
    <div className={className}>
      <div className={clsx('border-l-4 p-4', getGlobalStyles(variant))}>
        <div className="flex">
          <div className="flex-shrink-0">{getIcon(variant)}</div>
          <div className="ml-3">
            <p className={clsx('text-sm font-light', getTextStyles(variant))}>{message}</p>
          </div>
        </div>
      </div>
      {autoDismiss && (
        <div
          className={clsx(
            'border-b-2 rounded-b transform border-opacity-40 ease-out transition-width',
            isVisible ? ' w-full ' : 'w-0',
            getTimerStyles(variant)
          )}
          style={{ transitionDuration: `${visibleForMs}ms` }}
        />
      )}
    </div>
  );
};
