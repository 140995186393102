import { Outlet } from 'react-router-dom';
import { BoltIcon, PauseCircleIcon } from '@heroicons/react/24/outline';
import { SideMenu } from '../../components';

export const WorkflowsWrapper = () => {
  return (
    <div className="flex flex-col md:flex-row w-full h-full overflow-hidden">
      <SideMenu
        header="Workflows"
        menuItems={[
          {
            label: 'Active',
            renderIcon: () => <BoltIcon className="w-4 h-4 mr-3" />,
            to: '/workflows/active'
          },
          {
            label: 'Archived',
            renderIcon: () => <PauseCircleIcon className="w-4 h-4 mr-3" />,
            to: '/workflows/archived'
          }
        ]}
      />

      <div className="flex flex-col md:w-full md:h-full overflow-auto p-4 md:p-8">
        <Outlet />
      </div>
    </div>
  );
};
