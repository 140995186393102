import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonTypes,
  ConnectorsGrid,
  DeleteTenantConnectionModal,
  Dropdown,
  Input,
  LoadingTable,
  ViewTenantConnectionModal
} from '../../../components';
import { TenantConnection, useGetTenantConnectionsQuery } from '../../../store/tenant-connections';
import { CircleStackIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useGetConnectorsQuery } from '../../../store/connectors';
import { readableDate } from '../../../utils';

type TenantConnectionFilter = { id: keyof TenantConnection; label: string };

const dropdownFilters: TenantConnectionFilter[] = [
  {
    id: 'updatedAt',
    label: 'Recently updated'
  },
  {
    id: 'name',
    label: 'Name'
  }
];

function sortTenantConnections(data: TenantConnection[], sortKey: keyof TenantConnection) {
  return [...data].sort((a, b) => {
    return a[sortKey] > b[sortKey] ? 1 : -1;
  });
}

export const ConnectionsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(dropdownFilters[0]);
  const tenantConnectionsQuery = useGetTenantConnectionsQuery({});
  const connectorsQuery = useGetConnectorsQuery({});
  const [viewModal, setViewModal] = useState<TenantConnection | undefined>(undefined);
  const [deleteModal, setDeleteModal] = useState<TenantConnection | undefined>(undefined);
  const viewModalConnector = connectorsQuery.data?.find((connector) => connector.id === viewModal?.connectorId);

  return (
    <div>
      <DeleteTenantConnectionModal
        connection={deleteModal}
        onClose={() => {
          setDeleteModal(undefined);
        }}
      />
      <ViewTenantConnectionModal
        connection={viewModal}
        connector={viewModalConnector}
        onClose={() => {
          setViewModal(undefined);
        }}
      />
      <div className="flex flex-row justify-between pb-4 xl:pb-8">
        <div className="flex flex-row flex-grow items-center gap-x-1 md:gap-x-4">
          <div className="flex-grow md:max-w-xs">
            <Input
              type="text"
              value={searchTerm}
              label={''}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              id="search"
              placeholder="Search datasets"
              icon={'MagnifyingGlassCircleIcon'}
            />
          </div>
          {tenantConnectionsQuery.data && tenantConnectionsQuery.data.length > 0 && (
            <div className="flex flex-row justify-start">
              <Link to="/data/connectors">
                <Button variant={ButtonTypes.Primary} disabled={false} text="Create Connection" />
              </Link>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-end text-gray-500 font-light text-xs">
          <span className="pr-1">Sort by</span>
          <Dropdown
            items={dropdownFilters}
            onChange={(e) => setFilter({ id: e.id as keyof TenantConnection, label: e.label })}
            value={filter}
          />
        </div>
      </div>

      {tenantConnectionsQuery.isLoading ? (
        <LoadingTable />
      ) : tenantConnectionsQuery.data && tenantConnectionsQuery.data.length > 0 ? (
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-md">
          <table className="table-auto w-full border-collapse bg-white text-left text-sm text-gray-500">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                  Name
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden lg:table-cell text-center">
                  Created
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden lg:table-cell text-center">
                  Updated
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {tenantConnectionsQuery.data &&
                sortTenantConnections(tenantConnectionsQuery!.data!, filter.id).map((conn) => (
                  <tr
                    key={conn.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => {
                      setViewModal(conn);
                    }}
                  >
                    <td className="max-w-[500px] flex gap-3 px-3 lg:px-6 py-2 lg:py-4 font-normal text-gray-900 overflow-hidden">
                      <div className="relative h-10 w-10 hidden lg:block flex-shrink-0">
                        <CircleStackIcon />
                        <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white hidden lg:block"></span>
                      </div>
                      <div className="text-sm overflow-hidden flex-grow">
                        <div className="font-medium text-gray-700 overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {conn.name}
                        </div>
                        <div className="text-gray-400 overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {conn.description}
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 hidden lg:table-cell w-1 whitespace-nowrap">
                      <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 whitespace-nowrap">
                        <div className="flex gap-2">{readableDate(conn.createdAt)}</div>
                      </span>
                    </td>

                    <td className="px-6 py-4 hidden lg:table-cell w-1 whitespace-nowrap">
                      <span className="inline-flex items-center gap-1 rounded-full bg-sky-50 px-2 py-1 text-xs font-semibold text-sky-600 whitespace-nowrap">
                        {readableDate(conn.updatedAt)}
                      </span>
                    </td>

                    <td className="px-6 py-4 w-1 whitespace-nowrap">
                      <div className="flex flex-row justify-end items-center">
                        <button
                          className="m-2 h-6 w-6 text-red-600"
                          onClick={(event) => {
                            event.stopPropagation();
                            setDeleteModal(conn);
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <div className="flex flex-col">
            <h2 className="text-center text-2xl my-2">No Connections</h2>
            <p className="text-center font-light">Create the first connection here to use on the platform.</p>
            <div className="my-4 flex justify-center">
              <ConnectorsGrid />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
