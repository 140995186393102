import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { LoadingSpinner } from '../Loading';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setAuthentication } from '../../store/auth';

export const AppLayout = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const authToken = useAppSelector((state) => state.auth.decodedToken);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        if (!isLoading && isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            dispatch(setAuthentication(accessToken));
          }
        }
      } catch (e) {
        // TODO - handle this error
        console.error(e);
      }
    };
    getAccessToken();
  }, [isLoading, isAuthenticated, getAccessTokenSilently, dispatch]);

  if (isLoading) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <section className="flex flex-col bg-gray-50 min-h-screen w-screen h-screen">
      {!isLoading && authToken && <Outlet />}
    </section>
  );
};
