import { Link } from 'react-router-dom';
import { Input, LoadingList } from '../../components';
import { useGetWorkflowsQuery } from '../../store/workflows';
import { DateTime } from 'luxon';

import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Dropdown } from '../../components/Dropdown';
import { useState } from 'react';

const dropdownFilters = [
  {
    id: 'updatedAt',
    label: 'Recently run'
  },
  {
    id: 'name',
    label: 'Name'
  }
];

export const WorkflowsList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(dropdownFilters[0]);
  const { data, isLoading } = useGetWorkflowsQuery({ searchTerm, sortKey: filter.id });

  return (
    <div>
      <div className="grid grid-cols-2 items-center justify-between pb-4 xl:pb-8">
        <div className="grid-cols-1 md:max-w-xs">
          <Input
            type="text"
            value={searchTerm}
            label={''}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            id="search"
            placeholder="Search workflows"
            icon={'MagnifyingGlassCircleIcon'}
          />
        </div>
        <div className="grid-cols-1 flex flex-row justify-end text-gray-500 font-light text-xs">
          <span className="pr-1">Sort by</span>
          <Dropdown items={dropdownFilters} onChange={(e) => setFilter(e)} value={filter} />
        </div>
      </div>
      {isLoading && <LoadingList />}

      {!isLoading && data && (
        <div className="space-y-4">
          {data.map((workflow) => (
            <div key={workflow.id}>
              <Link to={`/workflows/${workflow.id}/summary`}>
                <div className="bg-white p-4 xl:p-8 rounded-md border hover:border-gradient-right hover:border-opacity-40 transition-all border-gray-200 text-sm">
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col xl:flex-row xl:items-center space-y-2 xl:space-y-0 xl:space-x-4">
                      <h3 className="font-semibold">{workflow.name}</h3>
                      <span className="text-gray-400 hidden xl:block">•</span>
                      <p className="text-gray-500 font-light">{workflow.description}</p>
                      <span className="text-gray-400 hidden xl:block">•</span>
                      <p className="text-gray-500 font-light">
                        Last edit {DateTime.fromISO(workflow.updatedAt).toFormat('LLL dd, yyyy')}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <ChevronRightIcon className="w-5 h-5" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
