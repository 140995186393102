import ReactModal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { ModalHeader } from './ModalHeader';
import { Connector, ConnectorConfigValue, ConnectorConfigValueKind } from '../../store/connectors';
import { Button, ButtonTypes } from '../Button';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TenantConnectionConfigValue, useCreateTenantConnectionMutation } from '../../store/tenant-connections';
import { useGetTenantSecretsQuery } from '../../store/tenant-secret';
import { ReactHookFormSelect } from '../Input/ReactHookFormSelect';
import { ReactHookFormInput } from '../Input';
import { capitalise } from '../../utils';
import { useEffect, useState } from 'react';

type Props = {
  onClose: () => void;
  connector: Connector | undefined;
  configValues: ConnectorConfigValue[];
};

const staticFormSchema = z.object({
  name: z.string().trim().min(5, { message: 'is required' }),
  description: z.string().trim().min(5, { message: 'is required' }),
  configValues: z.object({})
});

export function CreateTenantConnectionModal(props: Props) {
  const [createTenantConnection, createTenantConnectionState] = useCreateTenantConnectionMutation();
  const tenantSecretsQuery = useGetTenantSecretsQuery({});
  const navigate = useNavigate();

  const [lazyConnector, setLazyConnector] = useState<Connector | undefined>(props.connector);

  useEffect(() => {
    if (props.connector !== undefined) {
      setLazyConnector(props.connector);
    }
  }, [props.connector, setLazyConnector]);

  const secretOptions = tenantSecretsQuery.data?.map((secret) => secret.name) || [];

  const dynamicConfigValuesSchema = Object.fromEntries(
    props.configValues.map((configValue) => [
      configValue.name,
      configValue.required
        ? z
            .string({ required_error: 'is required', invalid_type_error: 'must be a valid string' })
            .trim()
            .min(1, { message: 'is required' })
        : z.string().trim()
    ])
  );
  const formSchema = staticFormSchema.extend({ configValues: z.object(dynamicConfigValuesSchema) });

  const { register, handleSubmit, ...formMethods } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    try {
      const configValues: TenantConnectionConfigValue[] = props.configValues.map((configValue) => {
        if (configValue.kind === ConnectorConfigValueKind.SecretRef) {
          return {
            name: configValue.name,
            kind: configValue.kind,
            secretRef: data.configValues[configValue.name]
          };
        } else {
          return {
            name: configValue.name,
            kind: configValue.kind,
            value: data.configValues[configValue.name]
          };
        }
      });
      const res = await createTenantConnection({
        connectorId: props.connector!.id,
        name: data.name,
        description: data.description,
        configValues
      }).unwrap();
      if (res) {
        navigate('/data/connections');
        props.onClose();
      }
    } catch (err) {}
  };

  return (
    <ReactModal
      isOpen={!!props.connector && props.configValues.length > 0}
      onRequestClose={() => {
        props.onClose();
      }}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[600px] max-w-full h-[660px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Create connection modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-2 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader title={`Create ${lazyConnector?.displayName || 'Connection'}`} isLoading={false} />
          <FormProvider register={register} handleSubmit={handleSubmit} {...formMethods}>
            <form className="flex flex-col flex-grow gap-2 my-4" onSubmit={handleSubmit(onSubmit)}>
              <ReactHookFormInput name="name" required={true} />
              <ReactHookFormInput name="description" />
              {props.configValues.map((configValue) => (
                <ReactHookFormSelect
                  key={configValue.name}
                  name={`configValues.${configValue.name}`}
                  label={capitalise(configValue.name)}
                  options={secretOptions}
                  required={configValue.required}
                  unselectedMessage="-- select a secret --"
                />
              ))}

              <div className="flex flex-col flex-grow justify-end my-2">
                {createTenantConnectionState.error && (
                  <span className="text-red-600 text-center font-light text-sm">
                    Sorry we had a problem updating this secret, please try again later.
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-4 mx-4">
                <Button
                  fullWidth
                  variant={ButtonTypes.Primary}
                  disabled={createTenantConnectionState.isLoading}
                  text="Create"
                  type="submit"
                />
                <Button
                  fullWidth
                  variant={ButtonTypes.Secondary}
                  disabled={createTenantConnectionState.isLoading}
                  text="Cancel"
                  type="button"
                  onClick={() => {
                    props.onClose();
                  }}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </ReactModal>
  );
}
