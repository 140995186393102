import {
  CheckCircleIcon,
  CircleStackIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
  PlayCircleIcon
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { MdmDemoDataSource } from '..';

type Props = {
  dataSource: MdmDemoDataSource;
};

export function DataSourceProcessingTableRow(props: Props) {
  return (
    <tr className="hover:bg-gray-50">
      <td className="max-w-[600px] flex gap-3 px-3 lg:px-6 py-2 lg:py-2 font-normal text-gray-900 overflow-hidden">
        <div className="relative h-8 w-8 hidden lg:block flex-shrink-0">
          <CircleStackIcon />
        </div>
        <div className="text-sm overflow-hidden flex-grow flex flex-col justify-center">
          <div className="font-medium text-gray-700 overflow-hidden overflow-ellipsis whitespace-nowrap">
            {props.dataSource.file.name}
          </div>
        </div>
      </td>

      <td className="px-6 py-2 hidden lg:table-cell whitespace-nowrap relative">
        <div className="flex gap-x-0 items-center">
          <div className="flex-grow"></div>
          <div className="h-8 w-8 text-green-600 flex justify-center">
            <PlayCircleIcon />
          </div>

          <div className="h-1 w-[100px] -mx-1 bg-gray-200 flex-grow">
            <div
              style={{
                width: props.dataSource.languageDetection.required
                  ? `${props.dataSource.languageDetection.progress}%`
                  : '100%'
              }}
              className={clsx(
                'h-full animate-width',
                props.dataSource.languageDetection.required ? 'bg-green-600' : 'bg-blue-400'
              )}
            ></div>
          </div>
          {props.dataSource.languageDetection.progress === 100 ? (
            <div className="h-8 w-8 text-green-600 flex justify-center">
              <CheckCircleIcon />
            </div>
          ) : props.dataSource.languageDetection.required ? (
            <div className="h-8 w-8 text-gray-600 flex justify-center animate-pulse">
              <EllipsisHorizontalCircleIcon />
            </div>
          ) : (
            <div className="h-8 w-8 text-blue-400 flex justify-center">
              <ExclamationCircleIcon />
            </div>
          )}
          <div className="h-1 w-[100px] -mx-1 bg-gray-200 flex-grow">
            <div
              style={{
                width: props.dataSource.translation.required ? `${props.dataSource.translation.progress}%` : '100%'
              }}
              className={clsx(
                'h-full animate-width',
                props.dataSource.translation.required ? 'bg-green-600' : 'bg-blue-400'
              )}
            ></div>
          </div>
          {props.dataSource.translation.progress === 100 ? (
            <div className="h-8 w-8 text-green-600 flex justify-center">
              <CheckCircleIcon />
            </div>
          ) : props.dataSource.translation.required ? (
            <div className="h-8 w-8 text-gray-600 flex justify-center animate-pulse">
              <EllipsisHorizontalCircleIcon />
            </div>
          ) : (
            <div className="h-8 w-8 text-blue-400 flex justify-center">
              <ExclamationCircleIcon />
            </div>
          )}
          <div className="h-1 w-[100px] -mx-1 bg-gray-200 flex-grow">
            <div
              style={{
                width: props.dataSource.dataCleansing.required ? `${props.dataSource.dataCleansing.progress}%` : '100%'
              }}
              className={clsx(
                'h-full animate-width',
                props.dataSource.dataCleansing.required ? 'bg-green-600' : 'bg-blue-400'
              )}
            ></div>
          </div>
          {props.dataSource.dataCleansing.progress === 100 ? (
            <div className="h-8 w-8 text-green-600 flex justify-center">
              <CheckCircleIcon />
            </div>
          ) : props.dataSource.dataCleansing.required ? (
            <div className="h-8 w-8 text-gray-600 flex justify-center animate-pulse">
              <EllipsisHorizontalCircleIcon />
            </div>
          ) : (
            <div className="h-8 w-8 text-blue-400 flex justify-center">
              <ExclamationCircleIcon />
            </div>
          )}
          <div className="flex-grow"></div>
        </div>
      </td>
    </tr>
  );
}
