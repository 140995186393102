const DEFAULT_ROW_COUNT = 5;

type Props = {
  rows?: number;
};

export const LoadingTable = ({ rows = DEFAULT_ROW_COUNT }: Props) => {
  return (
    <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md animate-pulse">
      <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              <div className="flex space-x-4 w-full">
                <div className="flex-1 space-y-3 py-1">
                  <div className="h-2 bg-slate-200 rounded"></div>
                  <div className="h-2 bg-slate-200 rounded"></div>
                </div>
              </div>
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              <div className="h-2 bg-slate-200 rounded"></div>
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              <div className="h-2 bg-slate-200 rounded"></div>
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              <div className="h-2 bg-slate-200 rounded"></div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          {Array.from({ length: rows }).map((_, idx) => (
            <tr className="hover:bg-gray-50" key={idx}>
              <td className="flex gap-3 px-6 py-4">
                <div className="relative h-10 w-10"></div>
                <div className="flex space-x-4 w-full">
                  <div className="flex-1 space-y-3 py-1">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="h-2 bg-slate-200 rounded"></div>
              </td>
              <td className="px-6 py-4">
                <div className="h-2 bg-slate-200 rounded"></div>
              </td>
              <td className="px-6 py-4">
                <div className="h-2 bg-slate-200 rounded"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
