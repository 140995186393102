import { CircleStackIcon } from '@heroicons/react/24/outline';
import { MdmDemoDataSourceCompleted } from '.';
import { humanReadableFileSize } from '../../../utils';

type Props = {
  dataSource: MdmDemoDataSourceCompleted;
};

function getSortedLanguagesDetected(languagePercentages: { [key: string]: number }) {
  return Object.entries(languagePercentages)
    .map(([key, value]) => ({ key, percentage: value }))
    .sort((a, b) => b.percentage - a.percentage);
}

export function LanguageDetectionResultsTableRow(props: Props) {
  const languagesFound = getSortedLanguagesDetected(props.dataSource.results.languageDetection.languagePercentages);

  return (
    <tr className="hover:bg-gray-50 cursor-pointer">
      <td className="max-w-[500px] flex gap-3 px-3 lg:px-6 py-2 lg:py-4 font-normal text-gray-900 overflow-hidden">
        <div className="relative h-10 w-10 hidden lg:block flex-shrink-0">
          <CircleStackIcon />
        </div>
        <div className="text-sm overflow-hidden flex-grow">
          <div className="font-medium text-gray-700 overflow-hidden overflow-ellipsis whitespace-nowrap">
            {props.dataSource.file.name}
          </div>
          <div className="text-gray-400 overflow-hidden overflow-ellipsis whitespace-nowrap">
            {humanReadableFileSize(props.dataSource.file.size)}
          </div>
        </div>
      </td>

      <td className="px-6 py-4 whitespace-nowrap">
        {languagesFound.slice(0, 5).map((lang) => (
          <span
            className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 whitespace-nowrap"
            key={lang.key}
          >
            <div className="flex gap-2">
              {lang.key} ({lang.percentage}%)
            </div>
          </span>
        ))}
      </td>
    </tr>
  );
}
