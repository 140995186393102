import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export type TenantSecretTags = {
  [name: string]: string;
};

export type TenantSecretTagOptions = {
  [name: string]: string[];
};

export type UpsertTenantSecretFormInputs = {
  name: string;
  value: string;
  tags: TenantSecretTags;
};

export const predefinedTenantSecretTagOptions: TenantSecretTagOptions = {
  environment: ['Production', 'Staging', 'Development']
};

export const predefinedTenantSecretTagDefaults: TenantSecretTags = {
  environment: 'Production'
};

export const SecretNameSchema = z.string().regex(/^[0-9a-zA-Z-]+$/);

export const upsertTenantSecretFormSchema = z.object({
  name: z
    .string({ required_error: 'is required', invalid_type_error: 'must be a valid string' })
    .min(1, { message: 'is required' })
    .trim()
    .min(4, { message: 'must use at least 4 characters' })
    .regex(/^[0-9a-zA-Z-]+$/, { message: 'must contain only letters and numbers' }),
  value: z
    .string({ required_error: 'is required', invalid_type_error: 'must be a valid string' })
    .min(1, 'is required'),
  tags: z.record(z.string().min(4, { message: 'must use at least 4 characters' }), z.string())
});

export function useUpsertTenantSecretForm() {
  const form = useForm<UpsertTenantSecretFormInputs>({
    defaultValues: { name: '', value: '', tags: predefinedTenantSecretTagDefaults },
    resolver: zodResolver(upsertTenantSecretFormSchema)
  });

  return form;
}
