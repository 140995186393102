import { Position } from 'reactflow';
import { createNode } from './WorkflowEditor.utils';

export const rsNodes = [
  createNode(
    {
      id: '1',
      displayName: 'Load Emails',
      type: 'read'
    },
    false,
    null,
    { x: 0, y: 0 }
  ),
  createNode(
    {
      id: '2',
      displayName: 'Filter eligible emails',
      type: 'action'
    },
    false,
    null,
    { x: 0, y: 150 },
    Position.Right,
    Position.Top
  ),
  createNode(
    {
      id: '3',
      type: 'forEach'
    },
    true,
    null,
    { x: 300, y: 0 },
    Position.Right,
    Position.Left
  ),
  createNode(
    {
      id: '4',
      type: 'action',
      displayName: 'Classify Email',
      inputs: {
        locations: ['Order', 'Complaint', 'Quote', 'Other']
      }
    },
    false,
    '3',
    { x: 25, y: 50 }
  ),
  createNode(
    {
      id: '5',
      type: 'write',
      displayName: 'Return to mailbox'
    },
    false,
    null,
    { x: 650, y: 150 },
    Position.Right,
    Position.Left
  )
];

export const rsEdges = [
  { id: '1-2', source: '1', target: '2', animated: true, label: 'Emails' },
  { id: '2-3', source: '2', target: '3', animated: true, label: 'Post filter' },
  { id: '3-5', source: '3', target: '5', animated: true, label: 'Classified Emails' }
];
