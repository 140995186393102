import React, { createContext, useContext, useState, useCallback } from 'react';
import { MKM_POC_ENRICHMENT_GROUPS } from './EnrichmentGroupConfigs';
import { EnrichmentGroupState, Source, SourceEnrichmentKind } from './types';
import { suggestProductEnrichmentGroup } from './api';

interface EnrichmentConfigContextProps {
  sources: Source[];
  sourceBlacklist: string[];
  sourceLimit: number;
  enrichmentGroups: EnrichmentGroupState;
  productDescription: string;
  verifyProduct: boolean;
  loadingSuggestedGroup: boolean;
  updateSource: (idx: number, source: Source) => void;
  removeSource: (idx: number) => void;
  addSource: () => void;
  updateBlacklistSource: (idx: number, value: string) => void;
  removeBlacklistSource: (idx: number) => void;
  addBlacklistSource: () => void;
  setSourceLimit: React.Dispatch<React.SetStateAction<number>>;
  updateEnrichmentField: (enrichmentGroupIndex: number, fieldIndex: number, value: string) => void;
  updateEnrichmentFieldKind: (enrichmentGroupIndex: number, fieldIndex: number, kind: SourceEnrichmentKind) => void;
  addEnrichmentField: (enrichmentGroupIndex: number) => void;
  removeEnrichmentField: (enrichmentGroupIndex: number, fieldIndex: number) => void;
  addEnrichmentGroup: () => void;
  renameEnrichmentGroup: (enrichmentGroupIndex: number, value: string) => void;
  removeEnrichmentGroup: (enrichmentGroupIndex: number) => void;
  updateProductDescription: (newDescription: string) => void;
  toggleVerifyProduct: () => void;
  suggestEnrichmentGroup: () => void;
}

const EnrichmentConfigContext = createContext<EnrichmentConfigContextProps | undefined>(undefined);

export function EnrichmentConfigProvider({ children }: React.PropsWithChildren<{}>) {
  const [productDescription, setProductDescription] = useState('');
  const [verifyProduct, setVerifyProduct] = useState(false);
  const [sources, setSources] = useState<Source[]>([]);
  const [sourceBlacklist, setSourceBlacklist] = useState<string[]>([]);
  const [sourceLimit, setSourceLimit] = useState(5);
  const [enrichmentGroups, setEnrichmentGroups] = useState<EnrichmentGroupState>(MKM_POC_ENRICHMENT_GROUPS);
  const [newGroupsAdded, setNewGroupsAdded] = useState(0);
  const [blacklistItemsAdded, setBlacklistItemsAdded] = useState(0);
  const [sourcesAdded, setSourcesAdded] = useState(0);
  const [loadingSuggestedGroup, setLoadingSuggestedGroup] = useState(false);

  const updateEnrichmentField = useCallback((enrichmentGroupIndex: number, fieldIndex: number, value: string) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = [...groupCopy.fields];
      groupCopy.fields[fieldIndex].fieldName = value;

      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const updateEnrichmentFieldKind = useCallback(
    (enrichmentGroupIndex: number, fieldIndex: number, kind: SourceEnrichmentKind) => {
      setEnrichmentGroups((prev) => {
        const prevCopy = [...prev];
        const groupCopy = { ...prev[enrichmentGroupIndex] };

        groupCopy.fields = [...groupCopy.fields];
        groupCopy.fields[fieldIndex].kind = kind;

        prevCopy[enrichmentGroupIndex] = groupCopy;

        return prevCopy;
      });
    },
    []
  );

  const addEnrichmentField = useCallback((enrichmentGroupIndex: number) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = [...groupCopy.fields, { fieldName: `New Field`, kind: SourceEnrichmentKind.Extracted }];
      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const removeEnrichmentField = useCallback((enrichmentGroupIndex: number, fieldIndex: number) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = groupCopy.fields.filter((_, index) => index !== fieldIndex);

      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const addEnrichmentGroup = useCallback(() => {
    setEnrichmentGroups((prev) => [...prev, { groupName: `New Group ${newGroupsAdded + 1}`, fields: [] }]);
    setNewGroupsAdded((prev) => prev + 1);
  }, [newGroupsAdded]);

  const renameEnrichmentGroup = useCallback((enrichmentGroupIndex: number, value: string) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.groupName = value;
      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const removeEnrichmentGroup = useCallback((enrichmentGroupIndex: number) => {
    setEnrichmentGroups((prev) => {
      let prevCopy = [...prev];

      return prevCopy.filter((_, index) => index !== enrichmentGroupIndex);
    });
  }, []);

  const updateSource = (idx: number, source: Source) => {
    setSources((prev) => [...prev.slice(0, idx), source, ...prev.slice(idx + 1, prev.length)]);
  };
  const removeSource = (idx: number) => {
    setSources((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]);
  };
  const addSource = () => {
    setSources((prev) => [...prev, { name: `Source ${sourcesAdded + 1}`, aiGenerated: false }]);
    setSourcesAdded((prev) => prev + 1);
  };
  const updateBlacklistSource = (idx: number, value: string) => {
    setSourceBlacklist((prev) => [...prev.slice(0, idx), value, ...prev.slice(idx + 1, prev.length)]);
  };
  const removeBlacklistSource = (idx: number) => {
    setSourceBlacklist((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]);
  };
  const addBlacklistSource = () => {
    setSourceBlacklist((prev) => [...prev, `Blacklist source ${blacklistItemsAdded + 1}`]);
    setBlacklistItemsAdded((prev) => prev + 1);
  };

  const toggleVerifyProduct = () => {
    setVerifyProduct((prev) => !prev);
  };

  const updateProductDescription = (newDescription: string) => {
    setProductDescription(newDescription);
  };

  const suggestEnrichmentGroup = async () => {
    setLoadingSuggestedGroup(true);

    const suggestedFields = await suggestProductEnrichmentGroup({ productDescription, enrichmentGroups });

    if (suggestedFields.length > 0) {
      setEnrichmentGroups((prev) => [...prev, { groupName: `Suggested Fields`, fields: suggestedFields }]);
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    setLoadingSuggestedGroup(false);
  };

  return (
    <EnrichmentConfigContext.Provider
      value={{
        sources,
        sourceBlacklist,
        sourceLimit,
        enrichmentGroups,
        productDescription,
        verifyProduct,
        loadingSuggestedGroup,
        setSourceLimit,
        updateSource,
        removeSource,
        addSource,
        updateBlacklistSource,
        removeBlacklistSource,
        addBlacklistSource,
        updateEnrichmentField,
        updateEnrichmentFieldKind,
        addEnrichmentField,
        removeEnrichmentField,
        addEnrichmentGroup,
        renameEnrichmentGroup,
        removeEnrichmentGroup,
        updateProductDescription,
        toggleVerifyProduct,
        suggestEnrichmentGroup
      }}
    >
      {children}
    </EnrichmentConfigContext.Provider>
  );
}

export const useEnrichmentConfig = () => {
  const context = useContext(EnrichmentConfigContext);
  if (context === undefined) {
    throw new Error('useEnrichmentConfigContext must be used within an EnrichmentConfigProvider');
  }
  return context;
};
