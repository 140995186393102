export function LoadingTile() {
  return (
    <div className="flex flex-col items-center gap-y-6 border rounded-md p-4 shadow-md animate-pulse">
      <div className="w-24 h-16 flex-grow flex justify-center items-center">
        <div className="w-full h-full bg-slate-200 rounded"></div>
      </div>

      <div className="py-4 flex-1 w-48">
        <div className="h-2 bg-slate-200 rounded"></div>
      </div>

      <div className="space-y-3 w-full">
        <div className="h-2 bg-slate-200 rounded"></div>
        <div className="h-2 bg-slate-200 rounded"></div>
      </div>
      <div className="h-8"></div>
    </div>
  );
}
