import { MdmDemoDataSourceCompleted } from '.';
import { LanguageDetectionResultsTableRow } from './LanguageDetectionResultsTableRow';

type Props = {
  dataSources: MdmDemoDataSourceCompleted[];
};

export function LanguageDetectionResultsTable(props: Props) {
  return (
    <div className="my-4 w-full overflow-x-auto rounded-lg border border-gray-200 shadow-md">
      <table className="table-auto w-full border-collapse bg-white text-left text-sm text-gray-500">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Dataset
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Languages Found
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          {props.dataSources.map((dataSource) => (
            <LanguageDetectionResultsTableRow key={dataSource.file.name} dataSource={dataSource} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
