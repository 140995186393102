import { Button, ButtonTypes } from '../Button';
import { Connector } from '../../store/connectors';

type Props = {
  icon: React.ReactElement;
  connector: Connector;
  onClickCreate: () => void;
};

export function ConnectorTile(props: Props) {
  return (
    <div className="flex flex-col items-center gap-y-6 border rounded-md p-4">
      <div className="w-24 flex-grow flex justify-center items-center">{props.icon}</div>
      <h3 className="text-xl">{props.connector.displayName}</h3>
      <p>{props.connector.description}</p>
      <Button variant={ButtonTypes.Primary} disabled={false} text="Create Connection" onClick={props.onClickCreate} />
    </div>
  );
}
