import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from './store';
import { jwtDecode } from 'jwt-decode';
// import { jwtDecode } from "jwt-decode";

type AccessToken = {
  alg: string;
  enc: string;
  iss: string;
  permissions: string[];
};

type EncodedAccessToken = string;

type AuthState = {
  accessToken: string | null;
  decodedToken: AccessToken | null;
};

const initialState: AuthState = {
  accessToken: null,
  decodedToken: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<EncodedAccessToken>) => {
      try {
        const decodedToken = jwtDecode<AccessToken>(action.payload);
        state.accessToken = action.payload;
        state.decodedToken = decodedToken;
      } catch (e) {
        console.error(e);
      }
    },
    removeAuthentication: (state) => {
      state.accessToken = null;
      state.decodedToken = null;
    }
  }
});

export const { setAuthentication, removeAuthentication } = authSlice.actions;

export const getUserToken = (state: RootState) => state.auth.decodedToken;

export default authSlice.reducer;
