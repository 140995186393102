import { Position } from 'reactflow';
import { createNode } from './WorkflowEditor.utils';

export const rsNodes = [
  createNode(
    {
      id: '1a',
      displayName: 'Load Product Data - Source 1',
      type: 'read'
    },
    false,
    null,
    { x: 0, y: 0 }
  ),
  createNode(
    {
      id: '1b',
      displayName: 'Load Product Data - Source 2',
      type: 'read'
    },
    false,
    null,
    { x: 250, y: 0 }
  ),
  createNode(
    {
      id: '2a',
      displayName: 'Source 1 Language Detection/Translation',
      type: 'action'
    },
    false,
    null,
    { x: 0, y: 140 }
  ),
  createNode(
    {
      id: '2b',
      displayName: 'Source 2 Language Detection/Translation',
      type: 'action'
    },
    false,
    null,
    { x: 250, y: 140 }
  ),
  createNode(
    {
      id: '3a',
      displayName: 'Source 1 Data cleaning / Pre-processing',
      type: 'action'
    },
    false,
    null,
    { x: 0, y: 280 }
  ),
  createNode(
    {
      id: '3b',
      displayName: 'Source 2 Data cleaning / Pre-processing',
      type: 'action'
    },
    false,
    null,
    { x: 250, y: 280 }
  ),
  createNode(
    {
      id: '4',
      displayName: 'Company Name Clustering',
      type: 'action'
    },
    false,
    null,
    { x: 0, y: 440 }
  ),
  createNode(
    {
      id: '5',
      displayName: 'Match Products',
      type: 'action'
    },
    false,
    null,
    { x: 0, y: 580 },
    Position.Right
  ),
  createNode(
    {
      id: '6',
      displayName: 'AI Match Check',
      type: 'ai'
    },
    false,
    null,
    { x: 300, y: 580 },
    Position.Right,
    Position.Left
  ),
  createNode(
    {
      id: '7',
      displayName: 'AI Generate Product Descriptions',
      type: 'ai'
    },
    false,
    null,
    { x: 650, y: 580 },
    Position.Right,
    Position.Left
  ),
  createNode(
    {
      id: '8',
      displayName: 'Write Final Matches',
      type: 'write'
    },
    false,
    null,
    { x: 950, y: 580 },
    Position.Right,
    Position.Left,
    true
  )
];

export const rsEdges = [
  { id: '1a-2a', source: '1a', target: '2a', animated: true, label: 'Raw input data' },
  { id: '1b-2b', source: '1b', target: '2b', animated: true, label: 'Raw input data' },
  { id: '2a-3a', source: '2a', target: '3a', animated: true, label: 'Translated product data' },
  { id: '2b-3b', source: '2b', target: '3b', animated: true, label: 'Translated product data' },
  { id: '3a-4', source: '3a', target: '4', animated: true, label: 'Pre-processed data' },
  { id: '3b-4', source: '3b', target: '4', animated: true, label: 'Pre-processed data' },
  { id: '4-5', source: '4', target: '5', animated: true, label: 'Clean product data' },
  { id: '5-6', source: '5', target: '6', animated: true, label: 'Matched products' },
  { id: '6-7', source: '6', target: '7', animated: true, label: 'AI verified matched products' },
  { id: '7-8', source: '7', target: '8', animated: true, label: 'Final product matches' }
];
