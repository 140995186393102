import ReactModal from 'react-modal';
import { SubmitHandler } from 'react-hook-form';
import { capitalise } from '../../utils';
import { Button, ButtonTypes } from '../Button';
import { useUpsertTenantSecretMutation } from '../../store/tenant-secret';
import { ModalHeader } from './ModalHeader';
import { UpsertTenantSecretFormInputs, predefinedTenantSecretTagOptions, useUpsertTenantSecretForm } from '../../hooks';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function CreateSecretModal(props: Props) {
  const [upsertTenantSecret, upsertTenantSecretState] = useUpsertTenantSecretMutation();
  const { register, handleSubmit, watch, formState, setValue, setFocus } = useUpsertTenantSecretForm();

  const onSubmit: SubmitHandler<UpsertTenantSecretFormInputs> = async (data) => {
    try {
      const res = await upsertTenantSecret({ name: data.name, value: data.value, tags: data.tags }).unwrap();
      if (res) {
        props.onClose();
        toast.success(`Created secret ${data.name}`);
      }
    } catch (err) {
      toast.error(`Failed creating secret ${data.name}`);
      console.log(err);
    }
  };

  useEffect(() => {
    if (upsertTenantSecretState.error) {
      toast.error('Sorry we had a problem creating this secret, please try again later.');
    }
  }, [upsertTenantSecretState.error]);

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={() => {
        props.onClose();
      }}
      onAfterOpen={() => {
        setFocus('name');
      }}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[600px] max-w-full h-[420px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Create secret Modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-2 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader title="Create Secret" isLoading={upsertTenantSecretState.isLoading} />

          <form className="flex flex-col flex-grow gap-2 my-4" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="name"
                className={`block mb-0.5 text-xs ${formState.errors.name ? 'text-red-600' : 'text-gray-500'}`}
              >
                Name {formState.errors.name && <span>{formState.errors.name.message}</span>}
              </label>
              <div className="relative w-full">
                <input
                  {...register('name')}
                  className={`px-3 py-2 border focus:ring-0 text-gray-600 text-sm font-light rounded-lg focus:border-gradient-right focus:border-opacity-40 block w-full ${
                    formState.errors.name ? 'border-red-600' : 'border-gray-200'
                  }`}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="value"
                className={`block mb-0.5 text-xs ${formState.errors.value ? 'text-red-600' : 'text-gray-500'}`}
              >
                Value {formState.errors.value && <span>{formState.errors.value.message}</span>}
              </label>
              <div className="relative w-full">
                <input
                  {...register('value')}
                  className={`px-3 py-2 border focus:ring-0 text-gray-600 text-sm font-light rounded-lg focus:border-gradient-right focus:border-opacity-40 block w-full ${
                    formState.errors.value ? 'border-red-600' : 'border-gray-200'
                  }`}
                />
              </div>
            </div>
            {Object.entries(predefinedTenantSecretTagOptions).map(([key, values]) => (
              <div className="my-1" key={key}>
                <label
                  htmlFor="tags"
                  className={`block text-xs mb-1 ${formState.errors.tags ? 'text-red-600' : 'text-gray-500'}`}
                >
                  <span>{capitalise(key)}</span>{' '}
                  {formState.errors.tags && formState.errors.tags[key] && (
                    <span>{formState.errors.tags[key]!.message}</span>
                  )}
                </label>
                <div className="flex gap-2 text-sm">
                  {values.map((value) => (
                    <div
                      key={value}
                      onClick={() => {
                        setValue('tags', { ...watch('tags'), [key]: value });
                      }}
                      className={`text-xs px-4 py-1 rounded-full cursor-pointer ${
                        watch('tags')[key] === value
                          ? 'bg-blue-100 text-blue-800'
                          : 'bg-gray-100 hover:border-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="flex flex-col gap-4 m-4">
              <Button
                fullWidth
                variant={ButtonTypes.Primary}
                disabled={upsertTenantSecretState.isLoading}
                text="Create"
                type="submit"
              />
              <Button
                fullWidth
                variant={ButtonTypes.Secondary}
                disabled={upsertTenantSecretState.isLoading}
                text="Cancel"
                type="button"
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
}
