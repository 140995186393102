import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { env } from '../env';
import { prepHeaders } from './utils';

type Subscription = {
  id: string;
  name: string;
  tier: string;
  startsOn: string;
  createdAt: string;
  term: {
    period: string;
    value: number;
  };
  rateCard: { actionCosts: Array<{ actionId: string; costTokens: number }> };
  status: 'active' | 'inactive';
  tenantId: string;
  endsOn: string;
  autoRenewal: boolean;
  tokenAllowancePerPeriod: number;
  tokenBalance: {
    balance: number;
    updatedAt: string;
  };
};

type TokenLedgerEntry = {
  id: string;
  tenantId: string;
  subscriptionId: string;
  eventType: string;
  sourceEventId: string;
  description: string;
  amountTokens: number;
  timestamp: string;
  leaseConsumed?: {
    actionId: string;
    workflowId: string;
    workflowRunId: string;
  };
};

export const subscriptionsApi = createApi({
  reducerPath: 'subscriptions',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_FQDN}/subscription/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Subscriptions],
  endpoints: (builder) => ({
    getActiveSubscription: builder.query<Subscription | null, void>({
      query: () => {
        return {
          url: `subscriptions/active`
        };
      },
      providesTags: [TagTypes.Subscriptions]
    }),
    getTokenLedgerEntries: builder.query<
      Array<TokenLedgerEntry> | null,
      { workflowId?: string; workflowRunId?: string } | void
    >({
      query: (params) => {
        return {
          url: `subscriptions/active/token-ledger-entries`,
          params: {
            ...(params?.workflowId ? { workflowId: params.workflowId } : {}),
            ...(params?.workflowRunId ? { workflowRunId: params.workflowRunId } : {})
          }
        };
      },
      providesTags: [TagTypes.Subscriptions]
    })
  })
});

export const { useGetActiveSubscriptionQuery, useGetTokenLedgerEntriesQuery } = subscriptionsApi;
