import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
  typeof error === 'object' && error != null && 'status' in error;

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export const isApiErrorMessage = (error: unknown): error is { data: { message: string } } => {
  try {
    return (
      typeof error === 'object' &&
      error != null &&
      'data' in error &&
      'message' in (error as any).data &&
      typeof (error as any).data.message === 'string'
    );
  } catch (e) {
    return false;
  }
};

export const standardiseError = (error: FetchBaseQueryError | SerializedError) => {
  const fallback = 'Sorry! An error occurred. Our telemetry will pick this up and we will look in to it...';
  if (error) {
    if ('status' in error) {
      const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);

      return errMsg ?? fallback;
    } else {
      return error.message ?? fallback;
    }
  }

  return fallback;
};
