import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { SourceEnrichmentKind } from './types';
import { useEnrichmentConfig } from './EnrichmentConfig';

export function EnrichmentConfigPanelFields() {
  const {
    enrichmentGroups,
    loadingSuggestedGroup,
    updateEnrichmentField,
    updateEnrichmentFieldKind,
    addEnrichmentField,
    removeEnrichmentField,
    addEnrichmentGroup,
    renameEnrichmentGroup,
    removeEnrichmentGroup,
    suggestEnrichmentGroup
  } = useEnrichmentConfig();

  return (
    <div className="w-full">
      <h3 className="text-xl">Enrichment Fields</h3>
      <p className="my-2 text-sm text-gray-600">
        Add the fields you wish to enrich data for. Use groups to categorise the fields.
      </p>
      <div className="my-2 flex flex-col gap-2">
        {enrichmentGroups.map((enrichmentGroup, enrichmentGroupIndex) => (
          <div key={enrichmentGroupIndex}>
            <div className="flex">
              <input
                className="w-full my-2 border-white px-0 rounded-md h-8"
                value={enrichmentGroup.groupName}
                onChange={(event) => {
                  renameEnrichmentGroup(enrichmentGroupIndex, event.target.value);
                }}
              />
              <button
                className="h-8 w-8 p-1 text-gray-600"
                onClick={() => {
                  removeEnrichmentGroup(enrichmentGroupIndex);
                }}
              >
                <MinusCircleIcon />
              </button>
            </div>
            <div className="flex flex-col gap-2">
              {enrichmentGroup.fields.map((field, fieldIndex) => (
                <div className="flex items-center" key={fieldIndex}>
                  <input
                    className="flex-grow shadow-md border border-gray-300 rounded-md w-full text-sm h-8"
                    value={field.fieldName}
                    onChange={(event) => {
                      updateEnrichmentField(enrichmentGroupIndex, fieldIndex, event.target.value);
                    }}
                  />
                  <select
                    className="border-0 mx-0 text-xs w-28"
                    value={field.kind}
                    onChange={(event) => {
                      updateEnrichmentFieldKind(
                        enrichmentGroupIndex,
                        fieldIndex,
                        event.target.value as SourceEnrichmentKind
                      );
                    }}
                  >
                    <option className="mx-0" value={'extracted'}>
                      extracted
                    </option>
                    <option className="mx-0" value={'generated'}>
                      generated
                    </option>
                  </select>
                  <button
                    className="flex-shrink-0 h-8 w-8 p-1 text-gray-600"
                    onClick={() => {
                      removeEnrichmentField(enrichmentGroupIndex, fieldIndex);
                    }}
                  >
                    <MinusCircleIcon />
                  </button>
                </div>
              ))}
              <div className="flex">
                <div className="flex-grow shadow-md border border-gray-300 rounded-md">
                  <button
                    className="text-sm w-full text-center h-8 bg-gray-100 hover:bg-gray-200"
                    onClick={() => {
                      addEnrichmentField(enrichmentGroupIndex);
                    }}
                  >
                    Add Field
                  </button>
                </div>
                <div className="w-8"></div>
              </div>
            </div>
          </div>
        ))}
        <div className="flex gap-x-2">
          <button
            className="py-2 px-4 my-4 shadow-md border border-gray-300 rounded-md text-sm text-center h-8 bg-gray-100 hover:bg-gray-200"
            onClick={() => {
              addEnrichmentGroup();
            }}
          >
            Add Group
          </button>
          <button
            className="py-2 px-4 my-4 shadow-md border border-gray-300 rounded-md text-sm text-center h-8 bg-gray-100 hover:bg-gray-200 disabled:opacity-50"
            onClick={() => {
              suggestEnrichmentGroup();
            }}
            disabled={loadingSuggestedGroup}
          >
            {loadingSuggestedGroup ? 'Generating...' : 'Suggest Group'}
          </button>
        </div>
      </div>
    </div>
  );
}
