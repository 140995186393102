import { useState, useCallback, useEffect } from 'react';
import Papa from 'papaparse';
import { ArrowDownIcon, PlayIcon } from '@heroicons/react/24/outline';
import { FileUpload } from '../../../components';
import clsx from 'clsx';
import { ColumnIdentifier, EnrichmentDataTableHeader, SourceEnrichmentResultState } from './types';
import { createOutputData, createOutputHeader, downloadImages, escapeCsvValue, saveCsv } from './output';
import { enrichProduct, preloadImages, searchImages } from './api';
import { SectionHeader } from './SectionHeader';
import { EnrichmentConfigPanelSources } from './EnrichmentConfigPanelSources';
import { EnrichmentDataTable } from './EnrichmentDataTable';
import { generateUUID } from '../../../utils/uuid';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { EnrichmentConfigPanelFields } from './EnrichmentConfigPanelFields';
import { EnrichmentConfigPanelProduct } from './EnrichmentConfigPanelProduct';

export function EnrichmentDemo() {
  const { sources, sourceBlacklist, sourceLimit, enrichmentGroups } = useEnrichmentConfig();
  const [fileError, setFileError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [headers, setHeaders] = useState<EnrichmentDataTableHeader[] | null>(null);
  const [displayedTableData, setDisplayedTableData] = useState<string[][] | null>(null);
  const [sourceEnrichmentResults, setSourceEnrichmentResults] = useState<SourceEnrichmentResultState>({});
  const [enrichmentLoading, setEnrichmentLoading] = useState<string[]>([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [enrichAllLoading, setEnrichAllLoading] = useState(false);

  useEffect(() => {
    if (files.length === 1) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(null);
    }
  }, [files]);

  useEffect(() => {
    if (!!selectedFile) {
      parseCsvFile(selectedFile);
    }
  }, [selectedFile]);

  const parseCsvFile = (file: File) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results) => {
        if (results.data && results.data.length > 0) {
          // Use the first row as a header and the rest as data
          const [headerRow, ...data] = results.data as string[][];

          if (headerRow && data) {
            setHeaders(headerRow.map((name) => ({ id: generateUUID(), name, kind: null })));
            setDisplayedTableData(data);
          } else {
            setFileError('No valid headers found in the CSV file.');
          }
        } else {
          setFileError('Failed to parse headers from the CSV file.');
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setFileError('Error parsing CSV file. Please check the file format.');
        setHeaders(null);
        setDisplayedTableData(null);
      }
    });
  };

  const enrichProductRow = useCallback(
    async (rowIdx: number) => {
      if (!displayedTableData || !headers) {
        return;
      }
      setEnrichmentLoading((prev) => [...prev, `${rowIdx}`]);

      const productRow = displayedTableData[rowIdx];

      const identifiers: ColumnIdentifier[] = [];
      headers.forEach((header, idx) => {
        if (header.kind === 'identifier') {
          identifiers.push({ columnName: header.name, value: productRow[idx] });
        }
      });

      const enrichmentResult = await enrichProduct({
        identifiers,
        enrichmentGroups,
        sources: sources.map((source) => source.name),
        sourceBlacklist,
        sourceLimit
      });

      if (!enrichmentResult) {
        setEnrichmentLoading((prev) => [...prev.filter((loadingRowIdx) => loadingRowIdx !== `${rowIdx}`)]);
        return;
      }

      const imagesResult = await searchImages(enrichmentResult.cleanQuery, 3);

      setEnrichmentLoading((prev) => [...prev.filter((loadingRowIdx) => loadingRowIdx !== `${rowIdx}`)]);
      setSourceEnrichmentResults((prev) => ({
        ...prev,
        [`${rowIdx}`]: { ...enrichmentResult, images: imagesResult.images || [] }
      }));

      preloadImages(imagesResult);
    },
    [displayedTableData, headers, enrichmentGroups, sources, sourceBlacklist, sourceLimit]
  );

  const downloadResults = useCallback(async () => {
    if (!displayedTableData || !headers || Object.values(sourceEnrichmentResults).length === 0) {
      console.error(`no data to download`);
      return;
    }

    setDownloadLoading(true);

    const identifierHeaderNames: string[] = [];
    const identifierIndices: number[] = [];
    headers.forEach((header, index) => {
      if (header.kind === 'identifier') {
        identifierHeaderNames.push(header.name);
        identifierIndices.push(index);
      }
    });

    const { dataHeader, summaryDataHeader } = createOutputHeader(identifierHeaderNames, sourceEnrichmentResults);
    const identifierData = displayedTableData.map((dataRow) =>
      dataRow.filter((_dataValue, dataValueIdx) => identifierIndices.includes(dataValueIdx))
    );
    const enrichmentResults = identifierData.map((_identData, identDataIdx) => {
      const rowEnrichmentResults = sourceEnrichmentResults[`${identDataIdx}`];
      if (!!rowEnrichmentResults) {
        return rowEnrichmentResults;
      }
      return null;
    });

    const output = createOutputData(identifierData, enrichmentResults, dataHeader.length);

    const outputCsv = [
      dataHeader.join(','),
      ...output.data.map((row) => row.map((value) => escapeCsvValue(value)).join(','))
    ].join('\n');
    saveCsv('output.csv', outputCsv);

    const outputSummaryCsv = [
      summaryDataHeader.join(','),
      ...output.summaryData.map((row) => row.map((value) => escapeCsvValue(value)).join(','))
    ].join('\n');
    saveCsv('summary.csv', outputSummaryCsv);

    await downloadImages(output.images);

    setDownloadLoading(false);
  }, [displayedTableData, headers, sourceEnrichmentResults]);

  const enrichAllProducts = useCallback(async () => {
    if (!displayedTableData) {
      console.error('no data to enrich');
      return;
    }
    setEnrichAllLoading(true);

    for (let i = 0; i < displayedTableData.length; i++) {
      await enrichProductRow(i);
    }

    setEnrichAllLoading(false);
  }, [displayedTableData, enrichProductRow]);

  const identifierHeadersSelected = headers
    ? headers.filter((header) => header.kind === 'identifier').length > 0
    : false;

  return (
    <div>
      <SectionHeader title="Product Enrichment" subtitle="Configure your required enrichment set up below." />

      <div className="my-8 flex justify-between gap-y-4 gap-x-12 flex-col lg:flex-row">
        <div className="w-full flex flex-col gap-y-6">
          <EnrichmentConfigPanelProduct />
          <EnrichmentConfigPanelSources />
        </div>
        <div className="w-full">
          <EnrichmentConfigPanelFields />
        </div>
      </div>
      <div className="my-4">
        <FileUpload
          onFileAdd={(newFiles) => {
            setFiles(newFiles);
          }}
          accept=".csv"
        />
      </div>
      <div className="my-4">
        <p className={clsx('my-2', fileError ? 'text-red-600' : '')}>
          {fileError ? fileError : files.length > 0 ? 'Files' : 'No files uploaded'}
        </p>
        <div className="flex flex-col gap-2 cursor-pointer">
          {files.map((file) => (
            <div
              key={file.name}
              onClick={() => {
                setSelectedFile(file);
                setFileError(null);
              }}
              className={clsx(
                'py-2 px-4 w-full shadow-md border-2 border-gray-300 rounded-md',

                fileError && selectedFile?.name === file.name
                  ? 'border-red-600'
                  : selectedFile?.name === file.name
                  ? 'border-2 border-green-600 text-black'
                  : 'text-gray-400'
              )}
            >
              {file.name}
            </div>
          ))}
        </div>
      </div>
      {headers && displayedTableData && (
        <div className="my-12">
          <div className="my-4 flex justify-end gap-x-2">
            <button
              className="px-4 py-2 bg-orange-300 rounded-md hover:bg-orange-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1"
              disabled={!identifierHeadersSelected || enrichAllLoading}
              onClick={() => {
                enrichAllProducts();
              }}
            >
              <span className="w-5">
                <PlayIcon />
              </span>
              <span>Enrich All</span>
            </button>
            <button
              className="px-4 py-2 bg-green-300 rounded-md hover:bg-green-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1"
              disabled={
                downloadLoading || !identifierHeadersSelected || Object.keys(sourceEnrichmentResults).length === 0
              }
              onClick={() => {
                downloadResults();
              }}
            >
              <span className="w-5">
                <ArrowDownIcon />
              </span>
              <span>Download Results</span>
            </button>
          </div>
          <p className="text-sm">
            Select the column headers which identify the product then use the actions to AI generate a source list,
            enrich or view results.
          </p>
          <EnrichmentDataTable
            onChangeHeaderKind={(headerId, newKind) => {
              setHeaders((prev) => {
                if (!prev) {
                  return prev;
                }
                const existingIndex = prev.findIndex((header) => header.id === headerId);
                return [
                  ...prev.slice(0, existingIndex),
                  { ...prev[existingIndex], kind: newKind },
                  ...prev.slice(existingIndex + 1, prev.length)
                ];
              });
            }}
            onClickRowEnrich={(rowId) => {
              enrichProductRow(parseInt(rowId));
            }}
            headers={headers}
            rows={displayedTableData.map((row, rowIdx) => ({
              id: `${rowIdx}`,
              loading: enrichmentLoading.includes(`${rowIdx}`),
              cells: row.map((cell) => ({ id: cell, value: cell }))
            }))}
            results={sourceEnrichmentResults}
            loading={enrichAllLoading}
            enrichmentDisabled={!identifierHeadersSelected}
          />
        </div>
      )}
    </div>
  );
}
