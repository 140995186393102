import { useEnrichmentConfig } from './EnrichmentConfig';

export function EnrichmentConfigPanelProduct() {
  const { productDescription, updateProductDescription } = useEnrichmentConfig();

  return (
    <div className="w-full flex flex-col justify-start gap-y-6">
      <div>
        <h3 className="text-xl">Description</h3>
        <p className="my-2 text-sm text-gray-600">Provide a brief description of the products being enriched.</p>
        <input
          className="flex-grow shadow-md border border-gray-300 rounded-md w-full text-sm h-8"
          value={productDescription}
          onChange={(event) => {
            updateProductDescription(event.target.value);
          }}
        />
      </div>
    </div>
  );
}
