import ReactModal from 'react-modal';
import { ModalHeader } from '../../../components/Modal/ModalHeader';
import { Button, ButtonTypes } from '../../../components/Button';
import { MdmDemoMatchAiVerification, MdmDemoMatchResult } from '.';

type Props = {
  onClose: () => void;
  matchResult: MdmDemoMatchResult | undefined;
};

const MATCH_RESULT_COMMON_FIELDS = ['match_id', 'match', 'reason', 'source', 'source_right'];

function parseLeftRightFields(matchResult?: MdmDemoMatchResult): { leftFields: string[]; rightFields: string[] } {
  const leftFields: string[] = [];
  const rightFields: string[] = [];

  if (matchResult) {
    Object.keys(matchResult).forEach((key) => {
      if (MATCH_RESULT_COMMON_FIELDS.includes(key)) {
        // Ignore common fields
      } else if (key.endsWith('_right')) {
        rightFields.push(key);
      } else {
        leftFields.push(key);
      }
    });
  }

  return { leftFields, rightFields };
}

export function ViewMDMDemoMatchResultModal(props: Props) {
  const { leftFields, rightFields } = parseLeftRightFields(props.matchResult);

  return (
    <ReactModal
      isOpen={!!props.matchResult}
      onRequestClose={() => {
        props.onClose();
      }}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[800px] max-w-full h-[680px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Create connection modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-2 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader
            title={
              !props.matchResult
                ? 'Match Result'
                : props.matchResult.match === MdmDemoMatchAiVerification.Yes
                ? 'AI Verified Match'
                : props.matchResult.match === MdmDemoMatchAiVerification.Maybe
                ? 'Potential Match'
                : 'AI Rejected Match'
            }
            isLoading={false}
          />
          <div className="my-2 flex flex-row items-center">
            <p className="text-sm font-thin">{props.matchResult?.reason}</p>
          </div>

          {props.matchResult && (
            <div className="flex flex-col flex-grow gap-2 my-2">
              <div>
                <label className="text-sm">Match ID</label>
                <p className="font-thin overflow-hidden overflow-ellipsis whitespace-nowrap text-xs">
                  {props.matchResult.match_id}
                </p>
              </div>

              <div className="flex w-full justify-between my-4">
                <div className="flex flex-col gap-y-2 items-start">
                  <div className="flex flex-col items-start">
                    <label className="text-sm">Data Source</label>
                    <p className="font-thin text-xs">{props.matchResult.source}</p>
                  </div>
                  {leftFields
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((field) => {
                      const value = props.matchResult![field];
                      if (!value) {
                        return null;
                      }
                      return (
                        <div className="flex flex-col items-start" key={field}>
                          <label className="text-sm">{field}</label>
                          <p className="font-thin text-xs">{value}</p>
                        </div>
                      );
                    })}
                </div>
                <div className="flex flex-col gap-y-2 items-end">
                  <div className="flex flex-col items-end">
                    <label className="text-sm text-right">Data Source</label>
                    <p className="font-thin text-xs text-right">{props.matchResult.source_right}</p>
                  </div>
                  {rightFields
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((field) => {
                      const value = props.matchResult![field];
                      if (!value) {
                        return null;
                      }
                      return (
                        <div className="flex flex-col items-end" key={field}>
                          <label className="text-sm text-right">{field.replace('_right', '')}</label>
                          <p className="font-thin text-xs text-right">{value}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4 m-4">
            <Button
              fullWidth
              variant={ButtonTypes.Secondary}
              text="Close"
              type="button"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
