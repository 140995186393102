import { useParams } from 'react-router-dom';
import { useGetWorkflowQuery } from '../../store/workflows';
import { LoadingSpinner } from '../../components';
import { DateTime } from 'luxon';
import cronstrue from 'cronstrue';
import { Params } from './Workflow.types';

export const WorkflowSummary = () => {
  const { workflowId } = useParams<keyof Params>() as Params;

  const { isLoading, data } = useGetWorkflowQuery(workflowId);

  return (
    <div>
      <div className="flex flex-col space-y-4">
        <div>
          <div>
            {isLoading && (
              <div className="w-full flex items-center justify-center" style={{ height: '450px' }}>
                <LoadingSpinner />
              </div>
            )}

            {!isLoading && data && (
              <div>
                <p className="text-md font-base mb-2">{data.name}</p>
                <p className="text-sm font-light mb-2">{data.description}</p>
                <p className="text-sm font-light">
                  Created at: {DateTime.fromISO(data.createdAt).toFormat('LLL dd, yyyy')}
                </p>
                <hr className="my-4" />
                <p className="text-sm font-light mb-2">{`Triggers:`}</p>
                {data.triggers.map((t) => {
                  if (t.type === 'schedule') {
                    return (
                      <p className="text-sm font-light" key={t.displayName}>{`${t.displayName}: ${cronstrue.toString(
                        t.cron
                      )}`}</p>
                    );
                  }
                  return <div key={t.displayName}></div>;
                })}

                {data.steps.length > 0 && (
                  <>
                    <hr className="my-4" />
                    <p className="text-sm font-light mb-2">{`Steps:`}</p>
                    {data.steps.map((t) => (
                      <p key={t.displayName} className="text-sm font-light">
                        - {t.displayName}
                      </p>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
