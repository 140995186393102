import { LoadingSpinner } from '../Loading';

type Props = {
  title: string;
  isLoading: boolean;
};

export function ModalHeader({ title, isLoading }: Props) {
  return (
    <div className="flex justify-between items-center">
      <h2 className="text-xl max-w-[90%]">{title}</h2>
      <div className="w-8 h-8">{isLoading && <LoadingSpinner size="xs" />}</div>
    </div>
  );
}
