export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary'
}

type ButtonProps = {
  variant?: ButtonTypes;
  type?: 'button' | 'submit' | 'reset' | undefined;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const Button = ({
  type = 'button',
  variant = ButtonTypes.Primary,
  className = '',
  fullWidth = false,
  disabled,
  text,
  onClick = undefined
}: ButtonProps) => {
  const getClasses = () => {
    const widthModifier = fullWidth ? 'w-full' : '';

    switch (variant) {
      case ButtonTypes.Primary:
        return `
          ${widthModifier} 
          bg-gradient-to-r from-primary-left to-gradient-right 
          text-white
          font-inter font-normal text-sm text-center
          tracking-tight
          rounded-3xl px-4 py-2
          focus:ring-0 focus:outline-none 
          transition ease-in-out 
          hover:transform hover:scale-105
          hover:disabled:shadow-none
          hover:disabled:scale-100
          disabled:opacity-60 disabled:cursor-not-allowed
          ${className}`;

      case ButtonTypes.Secondary:
        return `
          ${widthModifier} 
          bg-white 
          text-gray-800
          font-inter font-normal text-sm text-center
          tracking-tight
          border border-gray-800 border-opacity-10
          rounded-3xl px-4 py-2
          focus:ring-0 focus:outline-none 
          transition ease-in-out 
          hover:bg-gray-50
          hover:transform hover:scale-105
          hover:disabled:shadow-none
          hover:disabled:scale-100
          disabled:opacity-60 disabled:cursor-not-allowed
          ${className}`;
    }
  };

  return (
    <button type={type} onClick={onClick} disabled={disabled} className={getClasses()}>
      {text}
    </button>
  );
};
