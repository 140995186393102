import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { forwardRef } from 'react';

type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  label: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
};

export const Input = forwardRef(function Input(
  { value, onChange, label, type, id, required, disabled, placeholder, icon, fullWidth }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <>
      {label && (
        <label htmlFor={id} className="block mb-2 text-xs font-light text-gray-500">
          {label}
        </label>
      )}
      <div className={clsx('relative', fullWidth && 'w-full')}>
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {<MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />}
          </div>
        )}
        <input
          ref={ref}
          type={type}
          name={id}
          value={value}
          onChange={(e) => onChange(e)}
          id={id}
          className={`${
            icon ? 'pl-10' : 'pl-3'
          } px-3 py-2 border focus:ring-0 border-gray-200 text-gray-600 text-sm font-light rounded-lg focus:border-gradient-right focus:border-opacity-40 block w-full`}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </>
  );
});
