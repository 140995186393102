import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { env } from '../env';
import { prepHeaders } from './utils';

// @Radu - this is the sort of type that we would benefit from sharing between
// the FE/BE
type ProfileData = {
  userId: string;
  name: string;
  givenName: string;
  familyName: string;
  nickname: string;
  pictureUrl: string;
  email: string;
  emailVerified: boolean;
  locale: string;
  address: any;
  createdAt: string;
  updatedAt: string;
};

export const profileDataAPI = createApi({
  reducerPath: 'profileData',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_FQDN}/identity/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.ProfileData],
  endpoints: (builder) => ({
    getProfileData: builder.query<ProfileData, void>({
      query: () => {
        return {
          url: `/me`
        };
      },
      providesTags: [TagTypes.ProfileData]
    })
  })
});

export const { useGetProfileDataQuery } = profileDataAPI;
