import { useState } from 'react';
import { LockClosedIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  Button,
  ButtonTypes,
  Input,
  LoadingTable,
  Dropdown,
  CreateSecretModal,
  UpdateSecretModal,
  DeleteSecretModal
} from '../../../components';
import { TenantSecret, useGetTenantSecretsQuery } from '../../../store/tenant-secret';
import { readableDate } from '../../../utils';

type TenantSecretFilter = { id: keyof TenantSecret; label: string };

const dropdownFilters: TenantSecretFilter[] = [
  {
    id: 'updatedAt',
    label: 'Recently updated'
  },
  {
    id: 'name',
    label: 'Name'
  }
];

function sortTenantSecrets(secrets: TenantSecret[], sortKey: keyof TenantSecret) {
  return [...secrets].sort((a, b) => {
    return a[sortKey] > b[sortKey] ? 1 : -1;
  });
}

export function SecretsPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalSecret, setUpdateModalSecret] = useState<TenantSecret | undefined>(undefined);
  const [deleteModalSecretName, setDeleteModalSecretName] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<TenantSecretFilter>(dropdownFilters[0]);
  const tenantSecretsQuery = useGetTenantSecretsQuery({});

  return (
    <div>
      <CreateSecretModal
        isOpen={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
      />
      <UpdateSecretModal
        onClose={() => {
          setUpdateModalSecret(undefined);
        }}
        secret={updateModalSecret}
      />
      <DeleteSecretModal
        onClose={() => {
          setDeleteModalSecretName(undefined);
        }}
        secretName={deleteModalSecretName}
      />
      <div className="flex flex-col lg:flex-row gap-4 justify-between lg:items-center pb-4 xl:pb-8">
        <div className="flex flex-row flex-grow items-center gap-x-2 md:gap-x-4">
          <div className="flex-grow md:max-w-xs">
            <Input
              type="text"
              value={searchTerm}
              label={''}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              id="search"
              placeholder="Search secrets"
              icon={'MagnifyingGlassCircleIcon'}
            />
          </div>
          {!tenantSecretsQuery.isLoading && tenantSecretsQuery.data && tenantSecretsQuery.data.length > 0 && (
            <Button
              className="whitespace-nowrap"
              variant={ButtonTypes.Primary}
              disabled={false}
              text="Create Secret"
              onClick={() => {
                setCreateModalOpen(true);
              }}
            />
          )}
        </div>
        <div className="flex flex-row justify-end text-gray-500 font-light text-xs">
          <span className="pr-1">Sort by</span>
          <Dropdown
            items={dropdownFilters}
            onChange={(e) => setFilter({ id: e.id as keyof TenantSecret, label: e.label })}
            value={filter}
          />
        </div>
      </div>

      {tenantSecretsQuery.isLoading ? (
        <LoadingTable />
      ) : tenantSecretsQuery.data && tenantSecretsQuery.data.length > 0 ? (
        <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md">
          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                  Secret
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden lg:table-cell">
                  Tags
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden lg:table-cell text-center">
                  Updated
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {tenantSecretsQuery.data &&
                sortTenantSecrets(tenantSecretsQuery.data, filter.id).map((secret) => (
                  <tr
                    key={secret.name}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => {
                      setUpdateModalSecret(secret);
                    }}
                  >
                    <td className="flex gap-3 px-3 lg:px-6 py-2 lg:py-4 font-normal text-gray-900">
                      <div className="relative h-10 w-10 hidden lg:block">
                        <LockClosedIcon />
                        <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white hidden lg:block"></span>
                      </div>
                      <div className="text-sm">
                        <div className="font-medium text-gray-700 max-w-[200px] overflow-hidden overflow-ellipsis">
                          {secret.name}
                        </div>
                        <div className="text-gray-400">****************</div>
                      </div>
                    </td>

                    <td className="px-6 py-4 hidden lg:table-cell">
                      <div className="flex gap-2">
                        {Object.entries(secret.tags).map(([key, value]) => {
                          return (
                            <span
                              key={key}
                              className={`inline-flex items-center gap-1 rounded-full  px-2 py-1 text-xs font-semibold ${
                                key === 'environment'
                                  ? 'bg-red-50 text-red-600'
                                  : key === 'database'
                                  ? 'bg-violet-50 text-violet-600'
                                  : key === 'type'
                                  ? 'bg-yellow-50 text-yellow-600'
                                  : 'bg-blue-50 text-blue-600'
                              }`}
                            >
                              {value}
                            </span>
                          );
                        })}
                      </div>
                    </td>

                    <td className="px-6 py-4 hidden lg:table-cell w-1">
                      <span className="inline-flex items-center gap-1 rounded-full bg-sky-50 px-2 py-1 text-xs font-semibold text-sky-600 whitespace-nowrap">
                        {readableDate(secret.updatedAt)}
                      </span>
                    </td>

                    <td className="px-6 py-4 w-1">
                      <div className="flex flex-row items-center justify-end gap-4">
                        <button
                          className="m-2 h-6 w-6 text-red-600"
                          onClick={(event) => {
                            event.stopPropagation();
                            setDeleteModalSecretName(secret.name);
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <div className="flex flex-col">
            <h2 className="text-center text-2xl my-2">No Secrets</h2>
            <p className="text-center font-light">Create the first secret here to use on the platform.</p>
            <div className="my-4 flex justify-center">
              <Button
                variant={ButtonTypes.Primary}
                disabled={false}
                text="Create Secret"
                onClick={() => {
                  setCreateModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
