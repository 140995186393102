import { Link } from 'react-router-dom';
import { ProfileSettingsWrapper, Profile } from '../pages';

export const PROFILE_SETTINGS_ROUTES = [
  {
    element: <ProfileSettingsWrapper />,
    children: [
      {
        path: 'profile',
        element: <Profile />,
        handle: {
          crumb: () => <Link to="/profile">Profile</Link>
        }
      }
    ]
  }
];
