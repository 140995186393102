import { ArrowDownTrayIcon, ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { BookOpenIcon, ComputerDesktopIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Position } from 'reactflow';

export const defaultPosition = { x: 0, y: 0 };

const getIcon = (type: any) => {
  switch (type) {
    case 'action':
      return <ArrowDownTrayIcon className="h-3 w-3 text-white" />;
    case 'read':
      return <BookOpenIcon className="h-3 w-3 text-white" />;
    case 'forEach':
      return <ArrowUturnLeftIcon className="h-3 w-3 text-gray-800 rotate-180" />;
    case 'ai':
      return <ComputerDesktopIcon className="h-3 w-3 text-white rotate-180" />;
    case 'write':
      return <PencilSquareIcon className="h-3 w-3 text-white rotate-180" />;
    default:
      return <ArrowUturnLeftIcon className="h-3 w-3 text-gray-800 rotate-180" />;
  }
};

const getNodeColour = (type: any) => {
  switch (type) {
    case 'action':
      return 'bg-pink-700';
    case 'read':
      return 'bg-cyan-600';
    case 'forEach':
      return 'bg-gray-400';
    case 'ai':
      return 'bg-fireflai-500';
    case 'write':
      return 'bg-teal-500';
    default:
      return 'bg-gray-400';
  }
};

export const createNode = (
  step: any,
  nodeIsParent: boolean,
  parentId: string | null,
  position: { x: number; y: number },
  sourcePosition?: Position,
  targetPosition?: Position,
  isOutput?: boolean
) => {
  return {
    id: step.id,
    data: { label: createNodeLabel(step, nodeIsParent, step.displayName, step.inputs) },
    step,
    position,
    style: createNodeStyle(nodeIsParent),
    ...(parentId ? { parentNode: parentId, extent: 'parent' as const } : {}),
    ...(sourcePosition ? { sourcePosition } : {}),
    ...(targetPosition ? { targetPosition } : {}),
    ...(isOutput ? { type: 'output' } : {})
  };
};

export const createNodeStyle = (isParentNode: boolean) => {
  if (isParentNode) {
    return {
      background: 'transparent',
      padding: '1px',
      border: '1px solid #d7d7d7',
      width: '200px',
      height: '300px',
      cursor: 'pointer'
    };
  } else {
    return { padding: '1px', cursor: 'pointer', background: 'transparent', border: 'transparent' };
  }
};

// Function to create a label for a node
export const createNodeLabel = (step: any, isParentNode: boolean, displayName: string | null, inputs?: any) => {
  if (isParentNode) {
    return (
      <div className="bg-white p-2 flex">
        <span className="bg-gray-200 p-1 flex items-center rounded-md">
          {getIcon(step.type)}
          <span style={{ fontSize: '9px' }} className="ml-1 font-normal capitalize">
            {step.type}
          </span>
        </span>
        {inputs && inputs.locations && (
          <p
            className="bg-transparent p-1 rounded font-mono w-full text-gray-800"
            style={{ fontSize: '8px', width: '100%' }}
          >
            {inputs?.locations?.join(', ')}
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="bg-white rounded-md text-left p-2 space-y-2 border items-start justify-start">
      <div className={clsx('text-white p-1 flex max-w-min items-center rounded-md', getNodeColour(step.type))}>
        {getIcon(step.type)}
        <span style={{ fontSize: '9px' }} className="ml-1 font-normal capitalize">
          {step.type}
        </span>
      </div>
      <div className="text-left text-xs">{step.displayName}</div>

      {inputs && inputs.locations && (
        <>
          <hr />
          <div className="space-y-2 w-full">
            {inputs.locations.map((w: string) => (
              <div key={w} className="font-mono text-xs rounded-md bg-gray-100 p-1">
                {w}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
