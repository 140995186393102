import { useEffect, useState } from 'react';
import { MdmDemoDataSourceCompleted, MdmDemoMatchResult } from '.';
import { Button, ButtonTypes, Dropdown, DropdownFilter, LoadingTable } from '../../../components';
import { LanguageDetectionResultsTable } from './LanguageDetectionResultsTable';
import { MatchResultsTable } from './MatchResultsTable';
import { ViewMDMDemoMatchResultModal } from './ViewMDMDemoMatchResultModal';

type Props = {
  dataSources: MdmDemoDataSourceCompleted[];
  matchResults: MdmDemoMatchResult[];
};

const sortOptions: DropdownFilter[] = [
  {
    id: 'match_id',
    label: 'Match ID'
  },
  {
    id: 'match',
    label: 'AI Decision'
  },
  {
    id: 'source',
    label: 'Source Left'
  },
  {
    id: 'source_right',
    label: 'Source Right'
  },
  {
    id: 'reason',
    label: 'Reason'
  }
];

const limitOptions: DropdownFilter[] = [
  {
    id: '10',
    label: '10'
  },
  {
    id: '50',
    label: '50'
  },
  {
    id: '100',
    label: '100'
  }
];

function applyFilters(
  matchResults: MdmDemoMatchResult[],
  limit: number,
  sortFilterId: string,
  pageKey: number
): MdmDemoMatchResult[] {
  return matchResults
    .sort((a, b) => (b[sortFilterId] > a[sortFilterId] ? 1 : -1))
    .slice(pageKey * limit, (pageKey + 1) * limit);
}

export function MdmDemoStepMatchResults(props: Props) {
  const [sortFilter, setSortFilter] = useState(sortOptions[0]);
  const [limit, setLimit] = useState(parseInt(limitOptions[0].id, 10));
  const [pageKey, setPageKey] = useState(0);
  const [viewMatchResult, setViewMatchResult] = useState<MdmDemoMatchResult | undefined>();

  const filteredMatchResults = applyFilters(props.matchResults, limit, sortFilter.id, pageKey);
  const lastPageKey = Math.floor(props.matchResults.length / limit);

  useEffect(() => {
    // Reset page key when changing filters for simplicity - this can be improved later!
    setPageKey(0);
  }, [sortFilter, limit, setPageKey]);

  return (
    <div>
      <ViewMDMDemoMatchResultModal
        matchResult={viewMatchResult}
        onClose={() => {
          setViewMatchResult(undefined);
        }}
      />
      <div>
        <h2 className="text-2xl my-2">MDM Demo</h2>
        <p className="text-sm font-normal text-gray-400">View the results of the demo workflow below.</p>
      </div>
      <section className="my-8 w-full">
        <h2 className="text-xl my-2 self-start">Match Results</h2>
        <p className="text-sm font-normal text-gray-400">
          Match results show the pairing of records from multiple datasets. These matches are verified using AI as
          matching, potentially matching or not matching including a reason to justify the decision made.
        </p>
        <div>
          <div className="my-4 flex justify-end items-center gap-x-4">
            <div className="flex gap-x-4">
              <div className="flex flex-col">
                <label className="text-gray-600 text-xs">Limit</label>
                <Dropdown
                  items={limitOptions}
                  onChange={(e) => setLimit(parseInt(e.id, 10))}
                  value={{ id: limit.toString(), label: limit.toString() }}
                />
              </div>
              <div className="flex flex-col justify-start">
                <label className="text-gray-600 text-xs">Sort By</label>
                <Dropdown items={sortOptions} onChange={(e) => setSortFilter(e)} value={sortFilter} />
              </div>
            </div>
            <div className="flex justify-between mt-4 items-center gap-x-2">
              <Button
                variant={ButtonTypes.Primary}
                type="button"
                text={'Previous'}
                onClick={() => {
                  setPageKey((current) => Math.max(0, current - 1));
                }}
                disabled={pageKey === 0}
              />
              <span className="text-xs">{`Page ${pageKey + 1} of ${lastPageKey + 1}`}</span>
              <Button
                variant={ButtonTypes.Primary}
                type="button"
                text={'Next'}
                onClick={() => {
                  setPageKey((current) => Math.min(lastPageKey, current + 1));
                }}
                disabled={pageKey >= lastPageKey}
              />
            </div>
          </div>
          {props.matchResults.length > 0 ? (
            <div className="flex flex-col justify-center items-center">
              <MatchResultsTable
                matchResults={filteredMatchResults}
                onClickMatch={(matchId) => {
                  const foundMatch = props.matchResults.find((matchResult) => matchResult.match_id === matchId);
                  if (foundMatch) {
                    setViewMatchResult(foundMatch);
                  }
                }}
              />
            </div>
          ) : (
            <div>
              <LoadingTable />
            </div>
          )}
        </div>
      </section>
      <section className="my-8 w-full">
        <h2 className="text-xl my-2 self-start">Language Detection Results</h2>
        <p className="text-sm font-normal text-gray-400">
          The language is detected on a field by field basis throughout a dataset. The results are shown below for each
          dataset.
        </p>
        <div className="flex flex-col justify-center items-center">
          <LanguageDetectionResultsTable dataSources={props.dataSources} />
        </div>
      </section>
    </div>
  );
}
