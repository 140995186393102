export * from './MdmDemo';

export enum DemoStep {
  FileUpload,
  Processing,
  MatchResults
}

export enum MdmDemoMatchAiVerification {
  Yes = 'yes',
  No = 'no',
  Maybe = 'maybe'
}

export type MdmDemoMatchResult = MdmDemoMatchResultCommonFields & MdmDemoMatchResultKeyValues;

export type MdmDemoMatchResultCommonFields = {
  match_id: string;
  match: MdmDemoMatchAiVerification;
  reason: string;
  source: string;
  source_right: string;
};

export type MdmDemoMatchResultKeyValues = { [key: string]: string };

export type MdmDemoDataSourceResults = {
  languageDetection: {
    columnsDetected: number;
    languagesCount: number;
    languagePercentages: {
      [key: string]: number;
    };
  };
};

export type MdmDemoDataSourceCompleted = Required<MdmDemoDataSource>;

export type MdmDemoDataSource = {
  languageDetection: {
    required: boolean;
    progress: number;
  };
  translation: {
    required: boolean;
    progress: number;
  };
  dataCleansing: {
    required: boolean;
    progress: number;
  };
  file: File;
  // TODO: switch structure to be required/progress/result in root and each MDM stage below as they may extend??
  results?: MdmDemoDataSourceResults;
};

export const DEFAULT_DATA_SOURCE_VALUES: Omit<MdmDemoDataSource, 'file'> = {
  languageDetection: {
    required: true,
    progress: 0
  },
  translation: {
    required: true,
    progress: 0
  },
  dataCleansing: {
    required: true,
    progress: 0
  }
};

// TODO: For dev use
export const STATIC_DATA_SOURCES: MdmDemoDataSource[] = [
  {
    languageDetection: {
      required: true,
      progress: 0
    },
    translation: {
      required: true,
      progress: 0
    },
    dataCleansing: {
      required: true,
      progress: 0
    },
    file: { name: 'dataset1.csv', size: 49378432 } as File,
    results: {
      languageDetection: {
        columnsDetected: 3,
        languagesCount: 5,
        languagePercentages: {
          de: 65,
          en: 27,
          jp: 6,
          fr: 3,
          es: 2
        }
      }
    }
  },
  {
    languageDetection: {
      required: true,
      progress: 0
    },
    translation: {
      required: true,
      progress: 0
    },
    dataCleansing: {
      required: true,
      progress: 0
    },
    file: { name: 'dataset2.csv', size: 379243 } as File,
    results: {
      languageDetection: {
        columnsDetected: 3,
        languagesCount: 5,
        languagePercentages: {
          de: 27,
          en: 12,
          jp: 38,
          fr: 9,
          es: 12
        }
      }
    }
  },
  {
    languageDetection: {
      required: true,
      progress: 0
    },
    translation: {
      required: true,
      progress: 0
    },
    dataCleansing: {
      required: true,
      progress: 0
    },
    file: { name: 'dataset3.csv', size: 803482 } as File,
    results: {
      languageDetection: {
        columnsDetected: 3,
        languagesCount: 5,
        languagePercentages: {
          de: 17,
          en: 19,
          jp: 14,
          fr: 22,
          es: 28
        }
      }
    }
  }
];
