import { Outlet, useParams } from 'react-router-dom';
import { ChartBarIcon, InformationCircleIcon, PencilIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { SideMenu } from '../../components';
import { Params } from './Workflow.types';
import { useGetWorkflowQuery } from '../../store/workflows';

export const WorkflowInstanceWrapper = () => {
  const { workflowId } = useParams<keyof Params>() as Params;

  const { data } = useGetWorkflowQuery(workflowId);

  return (
    <div className="flex flex-col md:flex-row w-full h-full overflow-hidden">
      <SideMenu
        header={data?.name ?? 'Workflow'}
        menuItems={[
          {
            label: 'Summary',
            renderIcon: () => <InformationCircleIcon className="w-4 h-4 mr-3" />,
            to: `/workflows/${workflowId}/summary`
          },
          {
            label: 'Analytics',
            renderIcon: () => <ChartBarIcon className="w-4 h-4 mr-3" />,
            to: `/workflows/${workflowId}/analytics`
          },
          {
            label: 'Runs',
            renderIcon: () => <QueueListIcon className="w-4 h-4 mr-3" />,
            to: `/workflows/${workflowId}/runs`
          },
          {
            label: 'Editor',
            renderIcon: () => <PencilIcon className="w-4 h-4 mr-3" />,
            to: `/workflows/${workflowId}/editor`
          }
        ]}
      />

      <div className="flex flex-col md:w-full md:h-full overflow-auto p-4 md:p-8">
        <Outlet />
      </div>
    </div>
  );
};
