import { XMarkIcon } from '@heroicons/react/24/outline';

type SelectedNodeProps = {
  node: any;
  onClose: () => void;
};

export const SelectedNode = ({ node, onClose }: SelectedNodeProps) => {
  console.log(node);
  return (
    <div
      style={{ maxHeight: '90%', height: '90%' }}
      className="z-50 absolute bottom-0 right-0  p-8 border-gradient-right border border-opacity-40 rounded-2xl shadow-2xl min-h-fit flex flex-col bg-white outline-none focus:outline-none max-w-xl w-7/12"
    >
      {/* title */}
      <div className="flex justify-between items-center  pb-8 ounded-t">
        <h3 className="text-lg leading-normal">{node?.step?.displayName}</h3>
        <button
          className="p-1 ml-auto float-right rounded-2xl border border-gray-800 border-opacity-10 hover:bg-gray-100"
          onClick={() => onClose()}
        >
          <XMarkIcon className="h-5 w-5 text-grey-500" />
        </button>
      </div>
      {/* content */}

      <div className={`flex-auto h-full overflow-y-auto`}>
        {node.step.dependsOn && (
          <div className="space-y-2 mb-4">
            <div>Depends On</div>
            <div className="text-xs font-light">{node.step.dependsOn}</div>
          </div>
        )}
        {node.step.inputs && (
          <div className="space-y-2">
            <div>Inputs</div>

            <div className="">
              {node.step.inputs.locations && (
                <div className="space-y-2 w-full">
                  {node.step.inputs.locations.map((w: string) => (
                    <div key={w} className="font-mono text-xs rounded-md bg-gray-100 p-1">
                      {w}
                    </div>
                  ))}
                </div>
              )}

              {node.step.inputs.prompt && (
                <div className="bg-slate-700 font-extralight rounded-md p-3 break-words text-white space-y-2 w-full h-full text-xs font-mono">
                  {node.step.inputs.prompt}
                </div>
              )}

              {node.step.inputs.message && (
                <div className="bg-slate-700 font-extralight rounded-md p-3 break-words text-white space-y-2 w-full text-xs font-mono">
                  {node.step.inputs.message}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
