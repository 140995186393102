import { useEffect } from 'react';
import ReactModal from 'react-modal';
import { SubmitHandler } from 'react-hook-form';
import { capitalise } from '../../utils';
import { Button, ButtonTypes } from '../Button';
import { TenantSecret, useUpsertTenantSecretMutation } from '../../store/tenant-secret';
import { TagSelector } from '../TagSelector';
import { ModalHeader } from './ModalHeader';
import { UpsertTenantSecretFormInputs, predefinedTenantSecretTagOptions, useUpsertTenantSecretForm } from '../../hooks';
import { toast } from 'react-toastify';

type Props = {
  onClose: () => void;
  secret: TenantSecret | undefined;
};

export function UpdateSecretModal(props: Props) {
  const [upsertTenantSecret, upsertTenantSecretState] = useUpsertTenantSecretMutation();
  const { register, handleSubmit, watch, formState, setValue, setFocus } = useUpsertTenantSecretForm();

  const onSubmit: SubmitHandler<UpsertTenantSecretFormInputs> = async (data) => {
    try {
      const res = await upsertTenantSecret({ name: data.name, value: data.value, tags: data.tags }).unwrap();
      if (res) {
        props.onClose();
        toast.success(`Updated secret ${data.name}`);
      }
    } catch (err) {
      toast.error(`Failed updating secret ${data.name}`);
    }
  };

  useEffect(() => {
    if (props.secret) {
      setValue('name', props.secret.name);
      setValue('value', '');
      setValue('tags', props.secret.tags);
    }
  }, [setValue, props.secret]);

  useEffect(() => {
    if (upsertTenantSecretState.error) {
      toast.error('Sorry we had a problem creating this secret, please try again later.');
    }
  }, [upsertTenantSecretState.error]);

  return (
    <ReactModal
      isOpen={!!props.secret}
      onAfterOpen={() => {
        setFocus('value');
      }}
      onRequestClose={() => {
        props.onClose();
      }}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[600px] max-w-full h-[420px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Update secret modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-2 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader title={`Update Secret ${watch('name')}`} isLoading={upsertTenantSecretState.isLoading} />

          <form className="flex flex-col flex-grow gap-2 my-4" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="name"
                className={`block mb-0.5 text-xs ${formState.errors.name ? 'text-red-600' : 'text-gray-500'}`}
              >
                Name {formState.errors.name && <span>{formState.errors.name.message}</span>}
              </label>
              <div className="relative w-full">
                <input
                  {...register('name')}
                  disabled
                  className={`disabled:cursor-not-allowed bg-gray-200 px-3 py-2 border focus:ring-0 text-gray-600 text-sm font-light rounded-lg focus:border-gradient-right focus:border-opacity-40 block w-full ${
                    formState.errors.name ? 'border-red-600' : 'border-gray-200'
                  }`}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="value"
                className={`block mb-0.5 text-xs ${formState.errors.value ? 'text-red-600' : 'text-gray-500'}`}
              >
                Value {formState.errors.value && <span>{formState.errors.value.message}</span>}
              </label>
              <div className="relative w-full">
                <input
                  {...register('value')}
                  className={`px-3 py-2 border focus:ring-0 text-gray-600 text-sm font-light rounded-lg focus:border-gradient-right focus:border-opacity-40 block w-full ${
                    formState.errors.value ? 'border-red-600' : 'border-gray-200'
                  }`}
                />
              </div>
            </div>
            {Object.entries(predefinedTenantSecretTagOptions).map(([key, values]) => (
              <TagSelector
                key={key}
                label={capitalise(key)}
                values={values}
                selectedValue={watch('tags')[key]}
                onChange={(value) => {
                  setValue('tags', { ...watch('tags'), [key]: value });
                }}
                errorMessage={formState.errors.tags && formState.errors.tags[key]?.message}
              />
            ))}
            <div className="flex flex-col gap-4 m-4">
              <Button
                fullWidth
                variant={ButtonTypes.Primary}
                disabled={upsertTenantSecretState.isLoading}
                text="Update"
                type="submit"
              />
              <Button
                fullWidth
                variant={ButtonTypes.Secondary}
                disabled={upsertTenantSecretState.isLoading}
                text="Cancel"
                type="button"
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
}
