import { Link, useParams } from 'react-router-dom';
import { useGetMonitorRunsQuery, WorkflowInstance } from '../../store/workflows';
import { Params } from './Workflow.types';
import { Button, ButtonTypes, LoadingList } from '../../components';
import { DateTime } from 'luxon';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useGetTokenLedgerEntriesQuery } from '../../store/subscription';
import { useState } from 'react';

export const formatWorkflowStatus = (status: string) => {
  switch (status) {
    case 'Failed':
      return 'border-red-500 text-red-500';
    case 'Completed':
      return 'border-emerald-500 text-emerald-500';
    default:
      return 'border-gray-500 text-gray-500';
  }
};

const WorkflowRunCard = ({ workflowRun, workflowId }: { workflowId: string; workflowRun: WorkflowInstance }) => {
  const { isLoading, data } = useGetTokenLedgerEntriesQuery({ workflowRunId: workflowRun.instanceId });

  return (
    <div>
      {/* don't link to the instance page until there is actual content there */}
      <Link to="#" /*to={`/workflows/${workflowId}/runs/${workflowRun.instanceId}` }*/>
        <div className="bg-white p-4 xl:p-8 rounded-md border hover:border-gradient-right hover:border-opacity-30 transition-all border-gray-200 text-sm">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col xl:flex-row items-start xl:items-center space-y-2 xl:space-y-0 xl:space-x-4">
              <h3 className="font-semibold">{workflowRun.name}</h3>
              <span className="text-gray-400 hidden xl:block">•</span>
              <p
                className={clsx(
                  'font-light text-xs border px-2 py-1 border-opacity-40 rounded',
                  formatWorkflowStatus(workflowRun.status)
                )}
              >
                {workflowRun.status}
              </p>
              <span className="text-gray-400 hidden xl:block">•</span>
              <p className="text-gray-500 font-light">
                Ran on {DateTime.fromISO(workflowRun.createdTime).toFormat('LLL dd, yyyy HH:mm:ss')}
              </p>

              {!isLoading && data ? (
                <>
                  <span className="text-gray-400 hidden xl:block">•</span>
                  <p className="text-gray-500 font-light">
                    {data.reduce((acc, ledgerEntry) => acc + Math.abs(ledgerEntry.amountTokens), 0)} Tokens
                  </p>
                </>
              ) : (
                <div className="w-24 animate-pulse">
                  <div className="h-4 bg-slate-200 rounded"></div>
                </div>
              )}
            </div>
            <div className="flex space-x-2">
              <ChevronRightIcon className="w-5 h-5" />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const WorkflowRuns = () => {
  const perPage = 10;
  const [page, setPage] = useState(1);
  const { workflowId } = useParams<keyof Params>() as Params;
  const { isFetching, data } = useGetMonitorRunsQuery({ workflowId, page: page - 1, perPage });
  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(Math.max(1, page - 1));
  };

  return (
    <div>
      {isFetching && <LoadingList />}

      {!isFetching && data && (
        <>
          <div className="space-y-4">
            {data.data.map((workflow) => (
              <WorkflowRunCard key={workflow.instanceId} workflowId={workflowId} workflowRun={workflow} />
            ))}
          </div>
          {data.total > data.start + data.length && (
            <div className="flex justify-between mt-4 items-center space-y-4">
              <Button
                variant={ButtonTypes.Primary}
                type="button"
                text={'Previous'}
                onClick={previousPage}
                disabled={data.start === 0}
              />
              <span className="text-xs">{`Page ${page} of ${Math.ceil(data.total / perPage)}`}</span>
              <Button variant={ButtonTypes.Primary} type="button" text={'Next'} onClick={nextPage} />
            </div>
          )}
        </>
      )}

      {!isFetching && !data && (
        <div className="bg-white p-4 xl:p-8 rounded-md border hover:border-gradient-right hover:border-opacity-30 transition-all border-gray-200 text-sm">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col xl:flex-row items-start xl:items-center space-y-2 xl:space-y-0 xl:space-x-4">
              <h3>No runs found...</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
