import ReactModal from 'react-modal';
import { LoadingSpinner } from '.';

type Props = {
  isOpen: boolean;
};

export function LoadingOverlay(props: Props) {
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={() => {}}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[400px] max-w-full h-[200px] max-h-full overflow-auto rounded-xl outline-none"
      contentLabel="Loading Overlay"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-2 overflow-auto flex justify-center items-center">
        <LoadingSpinner />
      </div>
    </ReactModal>
  );
}
