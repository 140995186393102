import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, ButtonTypes } from '../Button';
import { useDeleteTenantSecretMutation } from '../../store/tenant-secret';
import { ModalHeader } from './ModalHeader';
import { toast } from 'react-toastify';

type Props = {
  onClose: () => void;
  secretName: string | undefined;
};

export function DeleteSecretModal(props: Props) {
  const [deleteTenantSecret, deleteTenantSecretState] = useDeleteTenantSecretMutation();
  const [lazySecretName, setLazySecretName] = useState<string>(props.secretName || '');

  useEffect(() => {
    if (props.secretName !== undefined) {
      setLazySecretName(props.secretName);
    }
  }, [props.secretName, setLazySecretName]);

  const onSubmit = async () => {
    try {
      await deleteTenantSecret(props.secretName!);
      props.onClose();
      toast.success(`Deleted secret ${lazySecretName}`);
    } catch (err) {
      toast.error(`Failed to delete secret ${lazySecretName}`);
    }
  };

  return (
    <ReactModal
      isOpen={!!props.secretName}
      onRequestClose={() => {
        props.onClose();
      }}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[600px] max-w-full h-[300px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Delete secret modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-6 pt-8 pb-4 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader title={`Delete Secret ${lazySecretName}`} isLoading={deleteTenantSecretState.isLoading} />

          <div className="my-4">
            <p className="text-md font-light text-center">Are you sure you want to delete this secret?</p>
          </div>

          <div className="flex flex-col gap-4 mx-4">
            <Button
              fullWidth
              variant={ButtonTypes.Primary}
              disabled={deleteTenantSecretState.isLoading}
              text="Delete"
              type="submit"
              onClick={() => {
                onSubmit();
              }}
            />
            <Button
              fullWidth
              variant={ButtonTypes.Secondary}
              disabled={deleteTenantSecretState.isLoading}
              text="Cancel"
              type="button"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
