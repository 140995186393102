import { useEffect, useState } from 'react';
import { DemoStep, MdmDemoDataSource, MdmDemoDataSourceCompleted, MdmDemoMatchResult, STATIC_DATA_SOURCES } from '.';
import { MdmDemoStepFileUpload } from './StepFileUpload';
import { MdmDemoStepProcessing } from './StepProcessing';
import { MdmDemoStepMatchResults } from './StepMatchResults';
import { loadStaticMatchResults } from './csv';

export function MdmDemo() {
  const [step, setStep] = useState(DemoStep.FileUpload);
  const [dataSources, setDataSources] = useState<MdmDemoDataSource[]>(STATIC_DATA_SOURCES);
  const [matchResults, setMatchResults] = useState<MdmDemoMatchResult[]>([]);

  useEffect(() => {
    (async () => {
      // Load match results from static CSV
      const matchResults = await loadStaticMatchResults();
      setMatchResults(matchResults);
    })();
  }, []);

  return (
    <div>
      {step === DemoStep.FileUpload && (
        <MdmDemoStepFileUpload
          dataSources={dataSources}
          setDataSources={setDataSources}
          onClickNext={() => {
            setStep(DemoStep.Processing);
          }}
        />
      )}

      {step === DemoStep.Processing && (
        <MdmDemoStepProcessing
          dataSources={dataSources}
          onClickNext={() => {
            setStep(DemoStep.MatchResults);
          }}
        />
      )}

      {step === DemoStep.MatchResults && (
        <MdmDemoStepMatchResults
          dataSources={dataSources as MdmDemoDataSourceCompleted[]}
          matchResults={matchResults.slice(0, matchResults.length > 0 ? 323 : 0)}
        />
      )}
    </div>
  );
}
