import { ChangeEvent } from 'react';

type Props = {
  onFileAdd: (newFiles: File[]) => void;
  accept?: string;
};

export function FileUpload(props: Props) {
  function handleFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    const fileInput = event.target;
    const newFileList = fileInput.files;

    if (newFileList) {
      const newFiles = Array.from(newFileList);
      props.onFileAdd(newFiles);
    }
  }

  return (
    <div className="flex justify-center w-full">
      <div className="relative flex items-center w-full max-w-full p-6 border border-dashed border-gray-900 rounded-md active:bg-gray-50 hover:bg-gray-50 cursor-pointer">
        <span className="flex-shrink-0 bg-gray-100 border border-gray-300 rounded-md py-2 px-4 mr-3 text-sm uppercase">
          Choose Files
        </span>
        <span className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">or drag and drop files here</span>
        <input
          type="file"
          multiple
          accept={props.accept}
          className="absolute left-0 top-0 h-full w-full opacity-0 cursor-pointer focus:outline-none"
          onChange={handleFileInputChange}
        />
      </div>
    </div>
  );
}
