import { Outlet } from 'react-router-dom';
import { CircleStackIcon, LockClosedIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { SideMenu } from '../../components';

export const DataWrapper = () => {
  return (
    <div className="flex flex-col md:flex-row w-full h-full overflow-hidden">
      <SideMenu
        header="Data"
        menuItems={[
          {
            label: 'Connectors',
            renderIcon: () => <Squares2X2Icon className="w-4 h-4 mr-3" />,
            to: '/data/connectors'
          },
          {
            label: 'Connections',
            renderIcon: () => <CircleStackIcon className="w-4 h-4 mr-3" />,
            to: '/data/connections'
          },
          {
            label: 'Secrets',
            renderIcon: () => <LockClosedIcon className="w-4 h-4 mr-3" />,
            to: '/data/secrets'
          }
        ]}
      />

      <div className="flex flex-col md:w-full md:h-full overflow-auto p-4 md:p-8">
        <Outlet />
      </div>
    </div>
  );
};
