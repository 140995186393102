import { MdmDemoDataSource } from '..';
import { DataSourceProcessingTableRow } from './DataSourceProcessingTableRow';

type Props = {
  dataSources: MdmDemoDataSource[];
};

export function DataSourceProcessingTable(props: Props) {
  return (
    <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-md">
      <table className="table-auto w-full border-collapse bg-white text-left text-sm text-gray-500">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Source Data
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">
              <div className="flex gap-x-0 items-center">
                <div className="flex-grow"></div>
                <div className="w-[8px] flex justify-center"></div>
                <div className="flex-grow w-[100px]"></div>
                <div className="w-[8px] flex justify-center">
                  <div>Language Detection</div>
                </div>
                <div className="flex-grow w-[100px]"></div>
                <div className="w-[8px] flex justify-center">
                  <div>Translation</div>
                </div>
                <div className="flex-grow w-[100px]"></div>
                <div className="w-[8px] flex justify-center">
                  <div>Data Cleansing</div>
                </div>
                <div className="flex-grow"></div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          {props.dataSources.map((dataSource) => (
            <DataSourceProcessingTableRow key={dataSource.file.name} dataSource={dataSource} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
