import { Edge, Node } from 'reactflow';
import { Workflow } from '../../../store/workflows';
import { createNode } from './WorkflowEditor.utils';

type GenerateFlowParams = {
  steps: Workflow['steps'];
  nodes: Node[];
  edges: Edge[];
  parentId: string | null;
  parentPosition: { x: number; y: number };
  level: number;
};

// Function to recursively generate nodes and edges, including handling nested steps
export const generateFlow = ({
  steps,
  nodes,
  edges,
  parentId,
  parentPosition = { x: 100, y: 0 },
  level = 0
}: GenerateFlowParams) => {
  const baseXOffset = 200;
  const baseYOffset = 300;
  const parentHeight = steps.length * baseYOffset;

  steps.forEach((step, index) => {
    const nodeIsParent = step.steps?.length > 0;
    const prevStepId = steps[index - 1]?.id;

    let positionX;
    let positionY;

    if (parentId) {
      positionX = parentPosition.x + (nodeIsParent ? baseXOffset : 10);
      positionY = 50 + 100 * index;
    } else {
      positionX = level * baseXOffset + parentPosition.x;
      positionY = parentPosition.y + index * baseYOffset;
    }

    const node = createNode(step, nodeIsParent, parentId, { x: positionX, y: positionY });

    nodes.push(node);

    if (prevStepId) {
      edges.push({
        id: `${prevStepId}-${step.id}`,
        source: prevStepId,
        target: step.id,
        type: 'smoothstep'
      });
    }

    if (step.steps) {
      let childInitialPosition = { ...node.position, y: node.position.y - parentHeight / 2 + baseYOffset / 2 };
      generateFlow({
        steps: step.steps,
        nodes,
        edges,
        parentId: step.id,
        parentPosition: childInitialPosition,
        level: level + 1
      });
    }
  });

  return { nodes, edges };
};
