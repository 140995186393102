import { Outlet } from 'react-router-dom';
import { SideMenu } from '../../components';
import { UserIcon } from '@heroicons/react/24/outline';

export const ProfileSettingsWrapper = () => {
  return (
    <div className="flex flex-col md:flex-row w-full h-full overflow-hidden">
      <SideMenu
        header="Profile"
        menuItems={[
          {
            label: 'Profile',
            renderIcon: () => <UserIcon className="w-4 h-4 mr-3" />,
            to: '/profile'
          }
        ]}
      />

      <div className="flex flex-col md:w-full md:h-full overflow-auto p-4 md:p-8">
        <Outlet />
      </div>
    </div>
  );
};
