import { Link, RouteObject } from 'react-router-dom';
import { ConnectionsPage, DataWrapper, ConnectorsPage } from '../pages';
import { SecretsPage } from '../pages/Data/Secrets';

export const DATA_ROUTES: RouteObject[] = [
  {
    path: '/data',
    handle: { crumb: () => <Link to="/data/connections">Data</Link> },
    element: <DataWrapper />,
    children: [
      {
        path: 'connectors',
        element: <ConnectorsPage />,
        handle: {
          crumb: () => {
            return <p>Connectors</p>;
          }
        }
      },
      {
        path: 'connections',
        element: <ConnectionsPage />,
        handle: {
          crumb: () => {
            return <p>Connections</p>;
          }
        }
      },
      {
        path: 'secrets',
        element: <SecretsPage />,
        handle: {
          crumb: () => {
            return <p>Secrets</p>;
          }
        }
      }
    ]
  }
];
