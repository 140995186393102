import { Link, RouteObject } from 'react-router-dom';
import { DemoWrapper, EnrichmentConfigProvider, EnrichmentDemo, MdmDemo } from '../pages/Demo';

export const DEMO_ROUTES: RouteObject[] = [
  {
    path: '/demo',
    handle: { crumb: () => <Link to="/demo">Demos</Link> },
    element: <DemoWrapper />,
    children: [
      {
        path: 'mdm',
        element: <MdmDemo />,
        handle: {
          crumb: () => {
            return <p>MDM</p>;
          }
        }
      },
      {
        path: 'enrichment',
        element: (
          <EnrichmentConfigProvider>
            <EnrichmentDemo />
          </EnrichmentConfigProvider>
        ),
        handle: {
          crumb: () => {
            return <p>Enrichment</p>;
          }
        }
      }
    ]
  }
];
