import fireflai_logo from '../../static/images/fireflai_logo.png';

export const Home = () => {
  return (
    <div className="flex flex-row w-full h-full overflow-hidden">
      {/* <Modal onClose={() => null} title="New Modal" /> */}

      <div className="flex flex-col w-full h-full overflow-auto p-4 md:p-8">
        <div className="flex flex-col md:flex-row md:space-x-8 items-center" style={{ height: '350px' }}>
          <img src={fireflai_logo} alt="Fireflai Logo" className="mx-auto max-w-xs mb-4 md:mb-0" />
          <div className=" text-center justify-center flex-grow items-center">
            <h1 className="font-light text-3xl mb-8">Introducing Generative AI For Business.</h1>

            <p className="font-light text-md mb-4">
              We need to think about what we want to actually show on this page.
            </p>
            <p className="font-light text-md mb-4">
              We could show an overview of some of the users, or workflows, or recent workflow runs. When clicked these
              would link through to the actual relevant pages with all of the data.
            </p>
            <p className="font-light text-md">
              We may not need this page at all, and can just default to the workflows page as the "home page".
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
