import { LinkIcon } from '@heroicons/react/24/outline';
import { Connector, useGetConnectorConfigQuery, useGetConnectorsQuery } from '../../store/connectors';
import { ConnectorTile } from '../ConnectorTile';
import { LoadingOverlay, LoadingTile } from '../Loading';
import { useEffect, useState } from 'react';
import { CreateTenantConnectionModal } from '../Modal';
import { toast } from 'react-toastify';

const LOADING_TILES_COUNT = 6;

export function ConnectorsGrid() {
  const connectorsQuery = useGetConnectorsQuery({});
  const [selectedConnector, setSelectedConnector] = useState<Connector | undefined>(undefined);
  const connectorConfigQuery = useGetConnectorConfigQuery(selectedConnector?.id || '', { skip: !selectedConnector });

  useEffect(() => {
    if (connectorConfigQuery.error) {
      // If there's an issue loading the connector config, deselect the connector to remove the loading overlay and warn user
      setSelectedConnector(undefined);
      toast.warn('Failed to load connector config');
    }
  }, [connectorConfigQuery.error]);

  if (connectorsQuery.data) {
    return (
      <div className="grid grid-cols-3 gap-4">
        <CreateTenantConnectionModal
          connector={selectedConnector}
          configValues={connectorConfigQuery.data || []}
          onClose={() => {
            setSelectedConnector(undefined);
          }}
        />
        <LoadingOverlay isOpen={!!selectedConnector && !connectorConfigQuery.data && !connectorConfigQuery.error} />
        {connectorsQuery.data.map((connector) => (
          <ConnectorTile
            key={connector.id}
            connector={connector}
            icon={<img src="/images/connectors/postgres.png" alt="PostgreSQL logo" />}
            onClickCreate={() => {
              setSelectedConnector(connector);
            }}
          />
        ))}
        <ConnectorTile
          icon={<img src="/images/connectors/postgres.png" alt="PostgreSQL logo" />}
          connector={{
            id: 'PostgeSQL Dataset',
            name: 'PostgeSQL Dataset',
            displayName: 'PostgeSQL Dataset',
            description: 'Create a dataset via PostgreSQL connection',
            version: 'v1.0.0',
            tags: []
          }}
          onClickCreate={() => {}}
        />
        <ConnectorTile
          icon={<img src="/images/connectors/mysql.jpg" alt="MySQL logo" />}
          connector={{
            id: 'MySQL Dataset',
            name: 'MySQL Dataset',
            displayName: 'MySQL Dataset',
            description: 'Create a dataset via MySQL connection',
            version: 'v1.0.0',
            tags: []
          }}
          onClickCreate={() => {}}
        />
        <ConnectorTile
          icon={<img src="/images/connectors/aws_s3.png" alt="AWS S3 logo" />}
          connector={{
            id: 'AWS S3 Dataset',
            name: 'AWS S3 Dataset',
            displayName: 'AWS S3 Dataset',
            description: 'Create a dataset via AWS S3 Bucket',
            version: 'v1.0.0',
            tags: []
          }}
          onClickCreate={() => {}}
        />
        <ConnectorTile
          icon={<img src="/images/connectors/mongodb.jpg" alt="MongoDB logo" />}
          connector={{
            id: 'MongoDB Dataset',
            name: 'MongoDB Dataset',
            displayName: 'MongoDB Dataset',
            description: 'Create a dataset via MongoDB connection',
            version: 'v1.0.0',
            tags: []
          }}
          onClickCreate={() => {}}
        />
        <ConnectorTile
          icon={<LinkIcon />}
          connector={{
            id: 'Remote URL',
            name: 'Remote URL',
            displayName: 'Remote URL',
            description: 'Create a dataset via PostgreSQL connection',
            version: 'v1.0.0',
            tags: []
          }}
          onClickCreate={() => {}}
        />
      </div>
    );
  }

  if (connectorsQuery.isLoading) {
    return (
      <div className="grid grid-cols-3 gap-4">
        {Array.from({ length: LOADING_TILES_COUNT }).map((_, idx) => (
          <LoadingTile key={idx} />
        ))}
      </div>
    );
  }

  return <div>Unable to load connectors</div>;
}
