import { Link } from 'react-router-dom';
import { SubscriptionWrapper } from '../pages/Subscription/SubscriptionWrapper';
import { Subscription, TokenUsage } from '../pages';

export const SUBSCRIPTION_ROUTES = [
  {
    path: '/subscription',
    element: <SubscriptionWrapper />,
    handle: {
      crumb: () => <Link to="/subscription/usage">Subscription</Link>
    },
    children: [
      {
        path: 'usage',
        element: <Subscription />,
        handle: {
          crumb: () => <Link to="/subscription/usage">Usage</Link>
        }
      },
      {
        path: 'token-usage',
        element: <TokenUsage />,
        handle: {
          crumb: () => <Link to="/subscription/token-usage">Token Usage</Link>
        }
      }
    ]
  }
];
