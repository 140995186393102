import { env } from '../../../env';
import {
  ColumnIdentifier,
  EnrichmentGroup,
  ImageSearchResponse,
  SourceEnrichmentField,
  SourceEnrichmentResponse
} from './types';

type EnrichmentRequestOptions = {
  identifiers: ColumnIdentifier[];
  enrichmentGroups: EnrichmentGroup[];
  sources: string[];
  sourceBlacklist: string[];
  sourceLimit: number;
};

export async function enrichProduct(options: EnrichmentRequestOptions): Promise<SourceEnrichmentResponse | undefined> {
  // Trim whitespace from field names prior to request
  const cleanEnrichmentGroups = options.enrichmentGroups.map((group) => ({
    ...group,
    fields: group.fields.map((field) => ({ ...field, fieldName: field.fieldName.trim() }))
  }));

  const enrichmentResponse = await fetch(`${env.API_ENDPOINT_ENRICHMENT}/source-enrich-product`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({ ...options, enrichmentGroups: cleanEnrichmentGroups })
  });

  return enrichmentResponse.json();
}

export async function searchImages(query: string, limit: number) {
  let imagesResult: ImageSearchResponse = { images: [] };

  try {
    const imagesResponse = await fetch(`${env.API_ENDPOINT_ENRICHMENT}/image-search`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ query, limit })
    });

    imagesResult = await imagesResponse.json();
  } catch (err) {
    console.log(err);
  }

  return imagesResult;
}

export function preloadImages(searchResponse: ImageSearchResponse) {
  searchResponse?.images?.forEach((img) => {
    const image = new Image();
    image.src = img.contentUrl;
  });
}

type SuggestEnrichmentGroupOptions = {
  productDescription: string;
  enrichmentGroups: EnrichmentGroup[];
};

export async function suggestProductEnrichmentGroup(
  options: SuggestEnrichmentGroupOptions
): Promise<SourceEnrichmentField[]> {
  try {
    const response = await fetch(`${env.API_ENDPOINT_ENRICHMENT}/suggest-enrichment-group`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(options)
    });
    const result: SourceEnrichmentField[] = await response.json();

    return result;
  } catch (err) {
    console.log(err);

    return [];
  }
}
