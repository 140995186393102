import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { env } from '../env';
import { prepHeaders } from './utils';
import { PaginatedResponse } from '../utils/types';

export type CreateInvitation = {
  email: string;
  roles: string[];
  sendEmail: boolean;
};

export type Invitation = Omit<CreateInvitation, 'sendEmail'> & {
  id: string;
  inviterName: string;
  inviteeEmail: string;
  createdAt: string;
  expiresAt: string;
};

export const invitationsAPI = createApi({
  reducerPath: 'invitations',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_FQDN}/identity/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Invitations],
  endpoints: (builder) => ({
    getInvitations: builder.query<PaginatedResponse<Array<Invitation>>, number | void>({
      query: (page = 0) => {
        return {
          url: `/user-invitations?page=${page}`
        };
      },
      providesTags: [TagTypes.Invitations]
    }),
    sendInvitation: builder.mutation<
      {
        id: string;
        inviterName: string;
        inviteeEmail: string;
        createdAt: string;
        expiresAt: string;
        roles: string[];
      },
      CreateInvitation
    >({
      query: (data: CreateInvitation) => ({
        url: `/user-invitations`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TagTypes.Invitations]
    }),
    revokeInvitation: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/user-invitations/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TagTypes.Invitations]
    })
  })
});

export const { useGetInvitationsQuery, useSendInvitationMutation, useRevokeInvitationMutation } = invitationsAPI;
