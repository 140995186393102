import { EnrichmentGroupState, SourceEnrichmentKind } from './types';

export const DEFAULT_ENRICHMENT_GROUPS: EnrichmentGroupState = [
  {
    groupName: 'Mandatory',
    fields: [
      { fieldName: 'Name', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Description', kind: SourceEnrichmentKind.Generated },
      { fieldName: 'Width', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Height', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Diameter', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Material', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Trade of User', kind: SourceEnrichmentKind.Generated },
      { fieldName: 'Industry', kind: SourceEnrichmentKind.Generated },
      { fieldName: 'Purpose', kind: SourceEnrichmentKind.Generated },
      { fieldName: 'Usage instructions', kind: SourceEnrichmentKind.Generated },
      { fieldName: 'Promotional Description', kind: SourceEnrichmentKind.Generated },
      { fieldName: 'Verbose Detailed Technical Description', kind: SourceEnrichmentKind.Generated }
    ]
  },
  {
    groupName: 'Optional',
    fields: [
      { fieldName: 'GTIN', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'SKU', kind: SourceEnrichmentKind.Extracted }
    ]
  }
];

export const MKM_POC_ENRICHMENT_GROUPS: EnrichmentGroupState = [
  {
    groupName: 'Required Fields',
    fields: [
      { fieldName: 'Brand', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Depth (mm)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Height (mm)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Width (mm) ', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Colour', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Energy Rating (Heating)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'ERP Rating', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Max Heat Output (KW)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'BTU Rating', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Energy Rating (Water)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Manufacturer Guarantee/ Manufacturer Warranty', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Tank Capacity (L)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Energy Rating', kind: SourceEnrichmentKind.Extracted }
    ]
  }
];
export const MKM_V1_ENRICHMENT_GROUPS: EnrichmentGroupState = [
  {
    groupName: 'Web',
    fields: [
      { fieldName: 'Colour', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Depth', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Fitting', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Material', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Model', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Weight', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Length', kind: SourceEnrichmentKind.Extracted }
    ]
  },
  {
    groupName: 'Product Properties',
    fields: [
      { fieldName: 'Part Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Description', kind: SourceEnrichmentKind.Generated },
      // { fieldName: 'Product Image', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Gas Council Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Product Group', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Worcester Webpage', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Height (mm)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Width (mm)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Depth (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Case Colour', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'BoilerType', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Fuel', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Burner', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Active Status', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Valid From (mm/yyyy)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Valid to (mm/yyyy)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Standard Guarantee (Years)', kind: SourceEnrichmentKind.Extracted },
      {
        fieldName: 'Standard Guarantee with any Greenstar System Filter (Years)',
        kind: SourceEnrichmentKind.Extracted
      },
      {
        fieldName:
          'Standard Guarantee with any Greenstar System Filter + £150 (Non-WAI Excelerate members only) (Years)',
        kind: SourceEnrichmentKind.Extracted
      },
      { fieldName: 'Volume Rebate Group', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Minimum rated input Central Heating (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum rated input Central Heating (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Output Central Heating (80ºC Flow @ 20 ΔT) (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum Output Domestic Hot Water (kW)', kind: SourceEnrichmentKind.Extracted },
      // {
      //   fieldName: 'Maximum Domestic Hot Water flow rate (30ºC Δ) Heat Store Hot (l/min)',
      //   kind: SourceEnrichmentKind.Extracted
      // },
      // {
      //   fieldName: 'Maximum Domestic Hot Water flow rate (30ºC Δ) Heat Store Cold (l/min)',
      //   kind: SourceEnrichmentKind.Extracted
      // },
      // { fieldName: 'Maximum Domestic Hot Water Flow rate (35ºC Δ) (l/min)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum Domestic Hot Water Flow rate (40ºC Δ) (l/min)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Minimum mains inlet pressure for max flow (bar)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Time to heat tank 15°C to 65°C (min)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Natural Gas G20 Consumption (m3/h)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Propane Gas (LPG) Consumption (kg/h)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'CO2 level at ↑maximum heat output (%)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'CO2 level at ↓minimum heat output (%)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total Product weight (lift weight) (kg)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total Product weight (Net Weight) (kg)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total Product weight (Gross Weight) (kg)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Packaged boiler dimensions (HxWxD) (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Seasonal space heating energy efficiency (%)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'SEDBUK (2005)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'SEDBUK (2009)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Energy efficiency class Domestic Hot Water (ErP)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Energy efficiency class Central Heating (ErP)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Water heating energy efficiency (%)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Emissions of nitrogen oxides (ErP) (mg/kWh)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (1)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (2)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (3)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (4)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (5)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (6)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (7)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (8)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (9)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (10)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (11)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Box Contents (12)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum Flue Lengths inc. terminal (100mm Horizontal) (m)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum Flue Lengths inc. terminal (100mm Vertical) (m)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum Flue Lengths inc. terminal (Plume)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum Flue Lengths inc. terminal (125mm Horizontal) (m)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum Flue Lengths inc. terminal (125mm Vertical) (m)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Overall clearance height (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Overall clearance depth (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'In front of appliance (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Overall clearance width (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Above the appliance (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Either side of appliance (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Below the appliance (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Compartment depth (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Appliance to removable door (mm)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum flow rate for a 40C ΔT over a 90 litres draw off', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum flow rate (±15%)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Fuel line (compression)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'CH control thermostat range (cut in/cut out)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'DHW slave tank control range (cut in / cut out)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Boiler manual reset overheat thermostat (cut out)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Flue reset overheat thermostat (cut out)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Tank manual reset overheat thermostat set point', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Code Plug ID Number', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Fuel Line (compression)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Boiler flow temperature range (cut in/cut out)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Boiler high limit thermostat set point (+ 0 / - 6°C)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Boiler manual reset overheat thermostat (cut out)2', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'European Article Number (EAN)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Commodity Code', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'X-Plant Material Status', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Serial Number', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Noise (dB(A))', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Features & Benefits', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Product Description (Long)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Power output with A -10/W351) 100% compressor speed (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Power output with A -7/W351), partial load (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'COP at A -7/W351) partial load (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Modulation range with A -7/W351) (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Power output with A +2/W351), 100% compressor speed (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Power output with A +2/W35, partial load (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'COP with A +2/W35 , partial load (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Modulation range with A +2/W35 (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Power output with A +7/W351), partial load (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'COP with A +7/W351), partial load (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Modulation range with A +7/W351) (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Cooling capacity with A 35/W71) (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'EER with A 35/W71) (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Cooling capacity with A 35/W181) (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'EER with A 35/W181) (kW)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Power Supply', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'IP rating', kind: SourceEnrichmentKind.Extracted },
      // {
      //   fieldName: 'Fuse rating for supplying the heat pump directly via the building connection',
      //   kind: SourceEnrichmentKind.Extracted
      // },
      // { fieldName: 'Max. added heating output', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Heat transfer medium', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Nominal flow rate L/s', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Internal pressure drop kPa', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Fan Motor (DC inverter) max. heating output (W)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Maximum air current m3/h', kind: SourceEnrichmentKind.Extracted },
      // {
      //   fieldName: 'Sound pressure level with a clearance of 1 m, load point according to 2013/811/EU (db(A))',
      //   kind: SourceEnrichmentKind.Extracted
      // },
      // { fieldName: 'Sound power Output (dB(A))', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Max. sound power A7/W55 (dB(A))', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Max. sound power "quiet operation" A7/W55 (dB(A))', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Max. sound power A7/W35 (dB(A))', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Refrigerant Volume (kg)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'maximum flow temperature, heat pump only  (°C)', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Installation altitude above sea level', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Refrigerant', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Efficiency Uplift', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Internet Connected', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Room Thermostat', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Wall Mounted', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Boiler Mounted', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Weather Compensation', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Load Compensation', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Boiler Connection Method', kind: SourceEnrichmentKind.Extracted },
      // { fieldName: 'Room Thermostat Connection Method', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Programmer Technology', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Programmer Time Period', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Room Thermostat Type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'CH Channel Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'DHW Channel Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Additional Functionality', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'ErP Class', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Display', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Protection Rating', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Transmitter Frequency', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Created By', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Product Filter', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Product Type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Diameter (mm)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Country of Origin', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Item Type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Path', kind: SourceEnrichmentKind.Extracted }
    ]
  }
];

export const HIGHBOURNE_ENRICHMENT_GROUPS: EnrichmentGroupState = [
  {
    groupName: 'Required',
    fields: [
      { fieldName: 'Supplier Name', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Supplier Part Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Group Master Description', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Main UOM', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Tax Class', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'ERP (Energy Efficiency)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'ERP Rating', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'CE Marked/UKCA', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'DOP Certification Provided', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'WEEE Required', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'WEEE Code', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Age Restriction', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Biocides', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Explosive', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Explosive Classification', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'FGas', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Hazardous Products', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Poison', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'SDS Required', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Substance of Very High Concern', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Reach Certification', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'WRAS / Reg 4 Compliance', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'EPD Required', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Contains Conflict Minerals', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Embodied Carbon', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Timber Regulations', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Shelf Life', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Copy', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 1', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Member of OPRL', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Primary packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of primary paper/card packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of primary plastic packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of primary wood packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of primary steel packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of primary aluminium packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of other primary packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Single Dimensions Height', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Single Dimensions Width', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Single Dimensions Length', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Single Dimensions Weight', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Single Dimensions Weight UOM', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Weight', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Weight UOM', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Country of Origin', kind: SourceEnrichmentKind.Extracted }
    ]
  },
  {
    groupName: 'Optional',
    fields: [
      { fieldName: 'Supplier Ledger Code', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Alternate UOM 1', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Alternate UOM 1 Factor', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'GTIN 1', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'GTIN 1 UOM', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'GTIN 2', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'GTIN 2 UOM', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'GTIN 3', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'GTIN 3 UOM', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Brand Name', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Range Description', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Manufacturer Model Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Commodity Code', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Gas Council Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Rating Image Provided', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Biocides Certification', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Hazardous Products Certification', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Poison Classification', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'SDS Certificate Issue Date', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'WRAS / Reg 4 Compliance Certificate Expiry Date', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Technical Data Sheet Required', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Timber Certification Number', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total Shelf Life', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Expiry Date/Manufacturing Date', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 2', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 3', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 4', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 5', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 6', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 7', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 8', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 9', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Features & Benefits 10', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Years Guaranteed', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Details of Warranty Provided', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Product Recyclability', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Plastic polymer code', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Recycled content of Product', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Recycled content of each packaging material', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Evidence of percentage of recycled plastic', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Secondary packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of secondary paper/card packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of secondary plastic packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of secondary wood packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of secondary steel packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of secondary aluminium packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of other secondary packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Tertiary packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of tertiary paper/card packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of tertiary plastic packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of tertiary wood packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of tertiary steel packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of tertiary aluminium packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of other tertiary packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of paper/card packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of plastic packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of wood packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of steel packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of aluminium packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Total weight of other packaging', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Inner Dimensions Height', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Inner Dimensions Width', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Inner Dimensions Length', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Inner Dimensions Weight', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Inner Dimensions Quantity', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Outer Dimensions Height', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Outer Dimensions Width', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Outer Dimensions Length', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Outer Dimensions Weight', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Outer Dimensions Quantity', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pallet Dimensions Height', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pallet Dimensions Width', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pallet Dimensions Length', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pallet Dimensions Weight', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pallet Dimensions Quantity', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pallet HI', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pallet TI', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Gallery Image Media Set data type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'SDS Media Set data type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Tech Media Set data type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'EPD Media Set data type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'DOP Media Set data type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'WRAS Media Set data type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'ERP Media Set data type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Biocides Media Set', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Reach Media Set', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Hazardous Products Media Set', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'BTU/h', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Colour', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Colour Family', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Finish', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Heat Output BTU', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Material', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Maximum Operating Pressure', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Maximum Temperature', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Mount Type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Number of Columns', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Number of Convectors', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Number of Panels', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Pipe Inlet Size', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Projection from Wall', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Projection from Wall Including Radiator', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Power Output (Wattage)', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Product Depth', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Product Height', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Radiator Type', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Radiator Water Volume', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Product Width', kind: SourceEnrichmentKind.Extracted },
      { fieldName: 'Radiator Style', kind: SourceEnrichmentKind.Extracted }
    ]
  }
];
