import { ArrowsRightLeftIcon, CheckIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MdmDemoMatchAiVerification, MdmDemoMatchResult } from '.';

type Props = {
  onClickMatch: (matchId: string) => void;
  matchResults: MdmDemoMatchResult[];
};

export function MatchResultsTable(props: Props) {
  return (
    <div className="w-full overflow-x-auto rounded-lg border border-gray-200 shadow-md">
      <table className="table-auto w-full border-collapse bg-white text-left text-sm text-gray-500">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Match
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden lg:table-cell text-center">
              Data Sources
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 hidden lg:table-cell text-center">
              Reason
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          {props.matchResults.map((matchResult) => (
            <tr
              className="hover:bg-gray-50 cursor-pointer"
              key={matchResult.match_id}
              onClick={() => {
                props.onClickMatch(matchResult.match_id);
              }}
            >
              <td className="w-[380px] flex gap-3 px-3 lg:px-6 py-2 lg:py-4 font-normal text-gray-900 overflow-hidden">
                <div className="flex justify-center items-center">
                  {matchResult.match === MdmDemoMatchAiVerification.Yes ? (
                    <MatchResultYesIcon />
                  ) : matchResult.match === MdmDemoMatchAiVerification.Maybe ? (
                    <MatchResultMaybeIcon />
                  ) : (
                    <MatchResultNoIcon />
                  )}
                </div>
                <div className="text-sm overflow-hidden flex-grow">
                  <div className="font-medium text-gray-700 overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {matchResult.match === MdmDemoMatchAiVerification.Yes
                      ? 'AI Verified Match'
                      : matchResult.match === MdmDemoMatchAiVerification.Maybe
                      ? 'Potential Match'
                      : 'AI Rejected Match'}
                  </div>
                  <div className="text-gray-400 overflow-hidden overflow-ellipsis text-xs">
                    {matchResult.match_id.split('_').map((matchPart, idx) => (
                      <p key={matchPart}>
                        {idx === 0 ? 'L:' : 'R:'} {matchPart}
                      </p>
                    ))}
                  </div>
                </div>
              </td>

              <td className="px-6 py-4 w-1">
                <div className="flex justify-center items-center gap-y-1">
                  <div className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 whitespace-nowrap">
                    <div className="flex gap-2">{matchResult.source}</div>
                  </div>
                  <div className="w-6">
                    <ArrowsRightLeftIcon />
                  </div>
                  <div className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 whitespace-nowrap">
                    <div className="flex gap-2">{matchResult.source_right}</div>
                  </div>
                </div>
              </td>

              <td className="px-6 py-4 hidden lg:table-cell whitespace-nowrap">
                <span className="whitespace-normal">{matchResult.reason}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function MatchResultYesIcon() {
  return (
    <div className="relative h-8 w-8 flex-shrink-0 text-green-400">
      <CheckIcon />
    </div>
  );
}

function MatchResultNoIcon() {
  return (
    <div className="relative h-8 w-8 flex-shrink-0 text-red-400">
      <XMarkIcon />
    </div>
  );
}

function MatchResultMaybeIcon() {
  return (
    <div className="relative h-8 w-8 flex-shrink-0 text-amber-400">
      <QuestionMarkCircleIcon />
    </div>
  );
}
