import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { useEnrichmentConfig } from './EnrichmentConfig';

export function EnrichmentConfigPanelSources() {
  const {
    sources,
    sourceBlacklist,
    sourceLimit,
    updateSource,
    removeSource,
    addSource,
    updateBlacklistSource,
    removeBlacklistSource,
    addBlacklistSource,
    setSourceLimit
  } = useEnrichmentConfig();

  return (
    <div className="w-full flex flex-col justify-start">
      <h3 className="text-xl">Sources</h3>
      <p className="my-2 text-sm text-gray-600">
        Select sources to use in the enrichment process. The sources can be defined as either source names or specific
        URLs.
      </p>
      <div className="w-full my-4">
        <h3 className="text-lg">Source Limit</h3>
        <p className="my-2 text-sm text-gray-600">
          Choose how many total sources should be used in the enrichment process.
        </p>
        <input
          value={sourceLimit}
          onChange={(event) => {
            const newLimit = parseInt(event.target.value);
            if (newLimit > 0) {
              setSourceLimit(newLimit);
            }
          }}
          type="number"
          className="flex-grow shadow-md border border-gray-300 rounded-md text-sm h-8"
        />
      </div>
      <div className="w-full my-4">
        <h3 className="text-lg">Preferred Sources</h3>
        <p className="my-2 text-sm text-gray-600">
          Choose sources which should be prioritised in the enrichment process.
        </p>
        <div className="my-4 flex flex-col gap-2">
          {sources.map((source, sourceIndex) => (
            <div className="flex" key={sourceIndex}>
              <input
                className="flex-grow shadow-md border border-gray-300 rounded-md w-full text-sm h-8"
                value={source.name}
                onChange={(event) => {
                  updateSource(sourceIndex, { name: event.target.value, aiGenerated: source.aiGenerated });
                }}
              />
              <button
                className="h-8 w-8 p-1 text-gray-600"
                onClick={() => {
                  removeSource(sourceIndex);
                }}
              >
                <MinusCircleIcon />
              </button>
            </div>
          ))}
          <div className="flex">
            <div className="flex-grow shadow-md border border-gray-300 rounded-md">
              <button
                className="text-sm w-full text-center h-8 bg-gray-100 hover:bg-gray-200"
                onClick={() => {
                  addSource();
                }}
              >
                Add Preferred Source
              </button>
            </div>
            <div className="w-8"></div>
          </div>
        </div>
      </div>
      <div className="w-full my-4">
        <h3 className="text-lg">Blacklist</h3>
        <p className="my-2 text-sm text-gray-600">Add sources which should not be used in the enrichment process.</p>
        <div className="my-4 flex flex-col gap-2">
          {sourceBlacklist.map((source, sourceIndex) => (
            <div className="flex" key={sourceIndex}>
              <input
                className="flex-grow shadow-md border border-gray-300 rounded-md w-full text-sm h-8"
                value={source}
                onChange={(event) => {
                  updateBlacklistSource(sourceIndex, event.target.value);
                }}
              />
              <button
                className="h-8 w-8 p-1 text-gray-600"
                onClick={() => {
                  removeBlacklistSource(sourceIndex);
                }}
              >
                <MinusCircleIcon />
              </button>
            </div>
          ))}
          <div className="flex">
            <div className="flex-grow shadow-md border border-gray-300 rounded-md">
              <button
                className="text-sm w-full text-center h-8 bg-gray-100 hover:bg-gray-200"
                onClick={() => {
                  addBlacklistSource();
                }}
              >
                Add Blacklist Source
              </button>
            </div>
            <div className="w-8"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
