import { useEffect } from 'react';
import { LoadingSpinner } from '../../components';
import { useAuth0 } from '@auth0/auth0-react';

export const Logout = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    const triggerLogout = async () => {
      await logout({
        logoutParams: { returnTo: window.location.origin }
      });
    };
    triggerLogout();
  }, [logout]);

  return (
    <div className="h-screen flex justify-center items-center w-full">
      <LoadingSpinner />
    </div>
  );
};
