import { DateTime } from 'luxon';
import { LoadingSpinner } from '../../components';
import { useGetProfileDataQuery } from '../../store/profileData';

export const Profile = () => {
  const { data, isLoading } = useGetProfileDataQuery();

  return (
    <div className="flex flex-col w-full">
      {isLoading && (
        <div className="w-full h-full flex justify-center items-center" style={{ height: '450px' }}>
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && data && (
        <div className="space-y-4 break-words">
          <div>
            <p className="text-md font-base mb-2 ">User ID</p>
            <p className="text-sm font-light mb-2">{data.userId}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Name</p>
            <p className="text-sm font-light mb-2">{data.name}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Email</p>
            <p className="text-sm font-light mb-2">{data.email}</p>
          </div>
          {data.createdAt && (
            <div>
              <p className="text-md font-base mb-2">Member Since</p>
              <p className="text-sm font-light mb-2">{DateTime.fromISO(data.createdAt).toFormat('dd LLL, yyyy')}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
