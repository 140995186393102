import { CheckCircleIcon, CircleStackIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { MdmDemoDataSource } from '..';
import clsx from 'clsx';
import { humanReadableFileSize } from '../../../../utils';

type Props = {
  dataSources: MdmDemoDataSource[];
  onRemoveDataSource: (idx: number) => void;
  onUpdateDataSource: (idx: number, data: MdmDemoDataSource) => void;
};

export function DataSourceSetupTable(props: Props) {
  return (
    <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-md">
      <table className="table-auto w-full border-collapse bg-white text-left text-sm text-gray-500">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Source Data File
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">
              File Size
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">
              Language Detection
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">
              Translation
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">
              Data Cleansing
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          {props.dataSources.map((dataSource, idx) => (
            <tr className="hover:bg-gray-50" key={dataSource.file.name}>
              <td className="max-w-[600px] flex gap-3 px-3 lg:px-6 py-2 lg:py-2 font-normal text-gray-900 overflow-hidden">
                <div className="relative h-8 w-8 hidden lg:block flex-shrink-0">
                  <CircleStackIcon />
                </div>
                <div className="text-sm overflow-hidden flex-grow flex flex-col justify-center">
                  <div className="font-medium text-gray-700 overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {dataSource.file.name}
                  </div>
                </div>
              </td>

              <td className="px-6 py-2 hidden lg:table-cell w-1 whitespace-nowrap">
                <div className="flex justify-center">
                  <span className="inline-flex items-center gap-1 rounded-full bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 whitespace-nowrap">
                    <div className="flex gap-2">{humanReadableFileSize(dataSource.file.size)}</div>
                  </span>
                </div>
              </td>

              <td className="px-6 py-2 w-1 whitespace-nowrap">
                <div className="flex flex-row justify-center items-center">
                  <button
                    className={clsx(
                      'm-2 h-6 w-6',
                      dataSource.languageDetection.required ? 'text-green-600' : 'text-red-600'
                    )}
                    onClick={() => {
                      props.onUpdateDataSource(idx, {
                        ...dataSource,
                        languageDetection: {
                          ...dataSource.languageDetection,
                          required: !dataSource.languageDetection.required
                        }
                      });
                    }}
                  >
                    {dataSource.languageDetection.required ? <CheckCircleIcon /> : <XCircleIcon />}
                  </button>
                </div>
              </td>

              <td className="px-6 py-2 w-1 whitespace-nowrap">
                <div className="flex flex-row justify-center items-center">
                  <button
                    className={clsx('m-2 h-6 w-6', dataSource.translation.required ? 'text-green-600' : 'text-red-600')}
                    onClick={() => {
                      props.onUpdateDataSource(idx, {
                        ...dataSource,
                        translation: {
                          ...dataSource.translation,
                          required: !dataSource.translation.required
                        }
                      });
                    }}
                  >
                    {dataSource.translation.required ? <CheckCircleIcon /> : <XCircleIcon />}
                  </button>
                </div>
              </td>

              <td className="px-6 py-2 w-1 whitespace-nowrap">
                <div className="flex flex-row justify-center items-center">
                  <button
                    className={clsx(
                      'm-2 h-6 w-6',
                      dataSource.dataCleansing.required ? 'text-green-600' : 'text-red-600'
                    )}
                    onClick={() => {
                      props.onUpdateDataSource(idx, {
                        ...dataSource,
                        dataCleansing: {
                          ...dataSource.dataCleansing,
                          required: !dataSource.dataCleansing.required
                        }
                      });
                    }}
                  >
                    {dataSource.dataCleansing.required ? <CheckCircleIcon /> : <XCircleIcon />}
                  </button>
                </div>
              </td>

              <td className="px-6 py-2 w-1 whitespace-nowrap">
                <div className="flex flex-row justify-end items-center">
                  <button
                    className="m-2 h-6 w-6 text-red-400"
                    onClick={() => {
                      props.onRemoveDataSource(idx);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
