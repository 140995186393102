import { DateTime } from 'luxon';
import { useGetTokenLedgerEntriesQuery } from '../../store/subscription';
import { useGetWorkflowsQuery } from '../../store/workflows';
import { LoadingList } from '../../components';
import { Alert } from '../../components/Alert';
import { standardiseError } from '../../utils/errors';

export const TokenUsage = () => {
  const {
    data: ledgerEntries,
    isLoading: ledgerEntriesIsLoading,
    isError: isLedgerEntriesError,
    error: ledgerEntriesError
  } = useGetTokenLedgerEntriesQuery();
  const { data: workflows } = useGetWorkflowsQuery({});
  return (
    <div>
      {ledgerEntriesIsLoading && <LoadingList />}

      {isLedgerEntriesError && ledgerEntriesError && (
        <div className="mb-8">
          <Alert variant="error" message={standardiseError(ledgerEntriesError)} />
        </div>
      )}

      {!ledgerEntriesIsLoading && ledgerEntries && workflows && (
        <div>
          <p className="text-md font-base mb-6">Transactions in the last 5 days</p>

          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Timestamp
                </th>
                <th scope="col" className="px-6 py-3">
                  Workflow
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Number of tokens
                </th>
              </tr>
            </thead>
            <tbody>
              {ledgerEntries.map((transaction, index) => (
                <tr key={index} className="odd:bg-white even:bg-gray-50 border-b ">
                  <th scope="row" className="px-6 py-4 whitespace-nowrap font-light text-gray-900">
                    {DateTime.fromISO(transaction.timestamp).toFormat('dd LLL, yyyy')}
                  </th>
                  <th scope="row" className="px-6 py-4 whitespace-nowrap font-light text-gray-900">
                    {transaction.leaseConsumed
                      ? workflows?.find((workflow) => workflow.id === transaction.leaseConsumed!.workflowId)?.name
                      : ''}
                  </th>
                  <td className="px-6 py-4 font-light text-gray-900">{transaction.description}</td>
                  <td className="px-6 py-4 font-light text-gray-900">{transaction.amountTokens}</td>
                </tr>
              ))}

              {ledgerEntries.length === 0 && (
                <tr className=" border-b ">
                  <th scope="row" className="px-6 py-4 font-light text-gray-900 whitespace-nowrap ">
                    No token usage
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
