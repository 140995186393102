import { configureStore } from '@reduxjs/toolkit';
import { profileDataAPI } from './profileData';
import authReducer from './auth';
import { invitationsAPI } from './invitations';
import { usersApi } from './users';
import { rolesApi } from './roles';
import { subscriptionsApi } from './subscription';
import { workflowsApi } from './workflows';
import { tenantSecretsApi } from './tenant-secret';
import { connectorApi } from './connectors';
import { connectionApi } from './tenant-connections';

export const store = configureStore({
  reducer: {
    [profileDataAPI.reducerPath]: profileDataAPI.reducer,
    [invitationsAPI.reducerPath]: invitationsAPI.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [workflowsApi.reducerPath]: workflowsApi.reducer,
    [tenantSecretsApi.reducerPath]: tenantSecretsApi.reducer,
    [connectorApi.reducerPath]: connectorApi.reducer,
    [connectionApi.reducerPath]: connectionApi.reducer,
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileDataAPI.middleware,
      invitationsAPI.middleware,
      usersApi.middleware,
      rolesApi.middleware,
      subscriptionsApi.middleware,
      workflowsApi.middleware,
      connectorApi.middleware,
      connectionApi.middleware,
      tenantSecretsApi.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production'
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
