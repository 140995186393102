type Props = {
  title: string;
  subtitle?: string;
};

export function SectionHeader(props: Props) {
  return (
    <div className="mb-8">
      <h2 className="text-2xl my-2">{props.title}</h2>
      {props.subtitle && (
        <p className="text-sm font-normal text-gray-600">Configure your required enrichment set up below.</p>
      )}
    </div>
  );
}
