import { useEffect, useMemo, useState } from 'react';
import { MdmDemoDataSource } from '.';
import { DataSourceProcessingTable } from './DataSourceTable/DataSourceProcessingTable';
import { Button } from '../../../components';

type Props = {
  dataSources: MdmDemoDataSource[];
  onClickNext: () => void;
};

export function MdmDemoStepProcessing(props: Props) {
  const dataSources = useMdmDemoDataProgress(props.dataSources);

  return (
    <div>
      <div>
        <h2 className="text-2xl my-2">MDM Demo</h2>
        <p className="text-sm font-normal">Upload source data files such as product data to get started.</p>
      </div>
      <div className="my-8 w-full flex flex-col justify-center items-center">
        <div className="my-4 w-full">
          <DataSourceProcessingTable dataSources={dataSources} />
        </div>
      </div>
      <div className="my-4 flex flex-row w-full">
        <Button
          text="View Match Results"
          disabled={!isProcessComplete(dataSources)}
          onClick={() => {
            props.onClickNext();
          }}
        />
      </div>
    </div>
  );
}

function isProcessComplete(dataSourcesState: MdmDemoDataSource[]) {
  for (let i = 0; i < dataSourcesState.length; i++) {
    const dataSource = dataSourcesState[i];
    if (dataSource.languageDetection.required && dataSource.languageDetection.progress < 100) {
      return false;
    } else if (dataSource.translation.required && dataSource.translation.progress < 100) {
      return false;
    } else if (dataSource.dataCleansing.required && dataSource.dataCleansing.progress < 100) {
      return false;
    }
  }

  return true;
}

const MIN_SPEED_CONSTANT = 0.25;
const MAX_SPEED_CONSTANT = 0.6;
const SPEED_CONSTANT_DIFF = MAX_SPEED_CONSTANT - MIN_SPEED_CONSTANT;

function calculateProgressMultiplier(dataSources: MdmDemoDataSource[]) {
  if (dataSources.length === 0) {
    throw new Error(`attempting to calculate progress multiplier without files`);
  }

  let largestFileSize = dataSources[0].file.size;
  let smallestFileSize = dataSources[0].file.size;

  dataSources.forEach((dataSource) => {
    if (dataSource.file.size > largestFileSize) {
      largestFileSize = dataSource.file.size;
    } else if (dataSource.file.size < smallestFileSize) {
      smallestFileSize = dataSource.file.size;
    }
  });

  if (smallestFileSize === largestFileSize) {
    return { smallestFileSize, largestFileSize, progressMultiplier: SPEED_CONSTANT_DIFF / smallestFileSize };
  }

  const fileSizeDiff = largestFileSize - smallestFileSize;

  const progressMultiplier = SPEED_CONSTANT_DIFF / fileSizeDiff;

  return { smallestFileSize, largestFileSize, progressMultiplier };
}

function calculateFileSizeFactor(fileSize: number, smallestFileSize: number, progressMultiplier: number) {
  return (fileSize - smallestFileSize) * progressMultiplier + MIN_SPEED_CONSTANT;
}

export const useMdmDemoDataProgress = (initialDataSources: MdmDemoDataSource[]) => {
  const [dataSources, setDataSources] = useState(initialDataSources);
  const { smallestFileSize, progressMultiplier } = useMemo(
    () => calculateProgressMultiplier(initialDataSources),
    [initialDataSources]
  );

  useEffect(() => {
    if (isProcessComplete(dataSources)) {
      return;
    }

    const intervalIds = dataSources.map((_, index) => {
      return setInterval(() => {
        setDataSources((prevDataSources) => {
          return prevDataSources.map((dataSource, i) => {
            if (i !== index) return dataSource;

            // Adjust the denominator to control speed
            const fileSizeFactor = calculateFileSizeFactor(dataSource.file.size, smallestFileSize, progressMultiplier);

            // Increment value based on file size
            const increment = Math.max(1, Math.round(100 / fileSizeFactor / 100));

            if (dataSource.languageDetection.progress < 100) {
              return {
                ...dataSource,
                languageDetection: {
                  ...dataSource.languageDetection,
                  progress: Math.min(100, dataSource.languageDetection.progress + increment)
                }
              };
            } else if (dataSource.translation.progress < 100) {
              return {
                ...dataSource,
                translation: {
                  ...dataSource.translation,
                  progress: Math.min(100, dataSource.translation.progress + increment)
                }
              };
            } else if (dataSource.dataCleansing.progress < 100) {
              return {
                ...dataSource,
                dataCleansing: {
                  ...dataSource.dataCleansing,
                  progress: Math.min(100, dataSource.dataCleansing.progress + increment)
                }
              };
            } else {
              // No change if all progress fields are at 100
              return dataSource;
            }
          });
        });
      }, 100);
    });

    return () => {
      intervalIds.forEach(clearInterval);
    };
  }, [dataSources, progressMultiplier, smallestFileSize]);

  return dataSources;
};
