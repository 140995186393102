import { RootState } from './store';

export const prepHeaders = (headers: Headers, { getState }: { getState: () => unknown }): Headers => {
  const token = (getState() as RootState).auth.accessToken;

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};
