import { ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { UIMatch, useLocation, useMatches } from 'react-router-dom';

type TypedUIMatch = UIMatch<unknown, { crumb: (data: any) => React.ReactNode }>;

const matchTypeguard = (match: unknown): match is TypedUIMatch => {
  if (match && typeof match === 'object' && 'handle' in match) {
    return (match as TypedUIMatch)?.handle?.crumb !== undefined;
  }

  return false;
};

export const Breadcrumbs = () => {
  let matches = useMatches();
  const typedMatches = matches.filter(matchTypeguard);
  const location = useLocation();
  const { pathname } = location;

  let crumbs = typedMatches
    .map((match) => {
      if (match?.handle?.crumb) {
        return {
          id: match.id,
          element: match.handle.crumb(match.data),
          active: match.pathname === pathname
        };
      }
      return null;
    })
    .filter((w) => Boolean(w?.element));

  return (
    <nav
      className="flex flex-shrink-0 border-b border-gray-800 border-opacity-10 py-3 px-3 md:px-8 overflow-x-auto"
      aria-label="Breadcrumb"
      style={{ WebkitOverflowScrolling: 'touch' }}
    >
      <ol className="flex items-center space-x-1 md:space-x-2">
        {crumbs.map(
          (crumb, i) =>
            crumb && (
              <li key={crumb.id} className="truncate">
                <div
                  className={clsx(
                    'flex items-center text-xs font-inter tracking-tight font-medium text-opacity-50 text-gray-950',
                    'transition ease-in-out',
                    'hover:text-gradient-right',
                    crumb.active && 'text-gradient-right !text-opacity-100'
                  )}
                >
                  {crumb.element}
                  {i !== crumbs.length - 1 && (
                    <ChevronRightIcon
                      className="ml-1 md:ml-2 h-3 w-3 text-gray-950 text-opacity-50"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </li>
            )
        )}
      </ol>
    </nav>
  );
};
