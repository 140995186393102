import { store } from '../store/store';
import { workflowsApi } from '../store/workflows';
import { WorkflowInstanceLoaderParams } from './types.routes';

export const defaultHandle = {
  crumb: () => null
};

export const workflowNameLoader = async (args: WorkflowInstanceLoaderParams) => {
  const { workflowId } = args.params;

  if (!workflowId) {
    return null;
  }

  if (!store.getState().auth.accessToken) {
    return { workflowId, data: null };
  }

  const p = store.dispatch(workflowsApi.endpoints.getWorkflow.initiate(workflowId));
  try {
    const response = await p.unwrap();
    return { workflowId, data: response };
  } catch (e) {
    return { workflowId, data: null };
  } finally {
    p.unsubscribe();
  }
};

export const workflowRunNameLoader = async (args: WorkflowInstanceLoaderParams) => {
  if (!args.params.workflowId || !args.params.instanceId) {
    return null;
  }

  const p = store.dispatch(
    workflowsApi.endpoints.getMonitorRun.initiate({
      workflowId: args.params.workflowId,
      instanceId: args.params.instanceId
    })
  );
  try {
    const response = await p.unwrap();
    return response;
  } catch (e) {
    return null;
  } finally {
    p.unsubscribe();
  }
};
