import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { env } from '../env';
import { prepHeaders } from './utils';
import { type PaginatedResponse } from '../utils/types';

export type User = {
  userId: string;
  name: string;
  email: string;
  roles: {
    id: string;
    name: string;
  }[];
};

export const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_FQDN}/identity/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Users],
  endpoints: (builder) => ({
    getUsers: builder.query<
      PaginatedResponse<Array<User>>,
      { page: number; searchTerm: string; sortKey: { label: string; id: string } }
    >({
      query: (options) => {
        return {
          url: `/users`,
          params: {
            page: options.page,
            searchTerm: options.searchTerm,
            sortKey: options.sortKey?.id
          }
        };
      },
      providesTags: [TagTypes.Users]
    })
  })
});

export const { useGetUsersQuery } = usersApi;
