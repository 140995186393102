import { DateTime } from 'luxon';
import { LoadingSpinner } from '../../components';
import { useGetActiveSubscriptionQuery } from '../../store/subscription';
import { Alert } from '../../components/Alert';
import { standardiseError } from '../../utils/errors';

export const Subscription = () => {
  const { data: subscription, isLoading: subscriptionIsLoading, error, isError } = useGetActiveSubscriptionQuery();

  return (
    <div className="w-full h-full">
      {isError && error && (
        <div className="mb-8">
          <Alert variant="error" message={standardiseError(error)} />
        </div>
      )}

      {subscriptionIsLoading && (
        <div className="w-full h-full flex justify-center items-center" style={{ height: '450px' }}>
          <LoadingSpinner />
        </div>
      )}

      {!subscriptionIsLoading && subscription && (
        <div className="space-y-4">
          <div>
            <p className="text-md font-base mb-2">Subscription Name</p>
            <p className="text-sm font-light mb-2">{subscription.name}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Tier</p>
            <p className="text-sm font-light mb-2">{subscription.tier}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Start Date</p>
            <p className="text-sm font-light mb-2">
              {DateTime.fromISO(subscription.startsOn).toFormat('dd LLL, yyyy')}
            </p>
          </div>
          <div>
            <p className="text-md font-base mb-2">End Date</p>
            <p className="text-sm font-light mb-2">{DateTime.fromISO(subscription.endsOn).toFormat('dd LLL, yyyy')}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Tokens Allowance</p>
            <div className="flex justify-between mb-1 lg:max-w-md">
              <span className="text-sm font-base text-fireflai-500">0</span>
              <span className="text-sm font-base text-fireflai-500">{subscription.tokenAllowancePerPeriod}</span>
            </div>
            <div className="w-full lg:max-w-md bg-gray-200 rounded ">
              <div
                className="bg-gradient-to-r from-gradient-left to-gradient-right text-xs font-medium text-blue-100 text-center p-2 leading-none rounded"
                style={{
                  width: `${(subscription.tokenBalance.balance / subscription.tokenAllowancePerPeriod) * 100}%`,
                  minWidth:
                    subscription.tokenBalance.balance / subscription.tokenAllowancePerPeriod > 0 ? '40px' : '0px'
                }}
              >
                {subscription.tokenBalance.balance}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
