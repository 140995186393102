import { MdmDemoMatchResult } from '.';
import { parse } from 'csv/browser/esm';

const FILE_LOCATION_MDM_DEMO_MATCH_RESULTS_CSV = '/data/demo/mdm/matches.csv';

export async function loadStaticMatchResults(): Promise<MdmDemoMatchResult[]> {
  const request = await fetch(FILE_LOCATION_MDM_DEMO_MATCH_RESULTS_CSV);
  const csvFile = await request.text();

  return new Promise(async (resolve, reject) => {
    const matchResults: MdmDemoMatchResult[] = [];

    parse(csvFile, { columns: true, skipRecordsWithEmptyValues: true }, function (err, rows) {
      if (err) {
        reject(err);
      }

      for (const row of rows) {
        matchResults.push(row);
      }

      resolve(matchResults);
    });
  });
}
