import { Link } from 'react-router-dom';
import { Invitations, UsersInvitationsWrapper } from '../pages';
import { Users } from '../pages/Users';
import { defaultHandle } from './utils.routes';

export const USERS_ROUTES = [
  {
    path: '/users',
    element: <UsersInvitationsWrapper />,
    handle: defaultHandle,
    children: [
      {
        path: 'list',
        element: <Users />,
        handle: {
          crumb: () => <Link to="/users/list">Users</Link>
        }
      },
      {
        path: 'invitations',
        element: <Invitations />,
        handle: {
          crumb: () => <Link to="/users/invitations">Invitations</Link>
        }
      }
    ]
  }
];
