import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { prepHeaders } from './utils';
import { env } from '../env';
import { z } from 'zod';
import { ConnectorConfigValueKind } from './connectors';

export const StaticTenantConnectionConfigValueSchema = z.object({
  kind: z.literal(ConnectorConfigValueKind.Static),
  name: z.string(),
  value: z.string()
});
export type StaticTenantConnectionConfigValue = z.infer<typeof StaticTenantConnectionConfigValueSchema>;

export const SecretRefTenantConnectionConfigValueSchema = z.object({
  kind: z.literal(ConnectorConfigValueKind.SecretRef),
  name: z.string(),
  secretRef: z.string()
});
export type SecretRefTenantConnectionConfigValue = z.infer<typeof SecretRefTenantConnectionConfigValueSchema>;

export const TenantConnectionConfigValueSchema = z.discriminatedUnion('kind', [
  StaticTenantConnectionConfigValueSchema,
  SecretRefTenantConnectionConfigValueSchema
]);
export type TenantConnectionConfigValue = z.infer<typeof TenantConnectionConfigValueSchema>;

export const TenantConnectionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  connectorId: z.string(),
  name: z.string(),
  description: z.string(),
  configValues: z.array(TenantConnectionConfigValueSchema),
  createdAt: z.string(),
  updatedAt: z.string()
});
export type TenantConnection = z.infer<typeof TenantConnectionSchema>;

export const TenantConnectionCreateSchema = TenantConnectionSchema.pick({
  connectorId: true,
  name: true,
  description: true,
  configValues: true
});
export type TenantConnectionCreate = z.infer<typeof TenantConnectionCreateSchema>;

export const connectionApi = createApi({
  reducerPath: 'Connections',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_ENDPOINT_APP}/tenant-connection/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Connections],
  endpoints: (builder) => ({
    // type must be defined, endpoint is ok
    getTenantConnections: builder.query<TenantConnection[], {}>({
      query: (_options) => {
        return {
          url: ``,
          method: 'GET'
        };
      },
      providesTags: [TagTypes.Connections]
    }),

    getTenantConnectionById: builder.query<TenantConnection, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: 'GET'
        };
      },
      providesTags: [TagTypes.Connections]
    }),

    createTenantConnection: builder.mutation<TenantConnection, TenantConnectionCreate>({
      query: (data) => {
        return {
          url: ``,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: [TagTypes.Connections]
    }),

    deleteTenantConnection: builder.mutation<TenantConnection, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: [TagTypes.Connections]
    })
  })
});

export const {
  useGetTenantConnectionsQuery,
  useGetTenantConnectionByIdQuery,
  useCreateTenantConnectionMutation,
  useDeleteTenantConnectionMutation
} = connectionApi;
